.profileDetailswrapper {
  position: relative;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  background-color: #fff;
  padding: 20px;
  margin-bottom: 40px;

  .EditProfileDots {
    position: absolute;
    right: 20px;
  }

  .bio {
    display: flex;
    justify-content: space-between;

    .profilePicWrapper {
      width: 100px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .profilepic {
        border-radius: 50%;
        width: 80px;
        height: 80px;
      }

      .actions {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        img {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      width: 100%;
      .name {
        font-size: 24px;
        font-weight: 600;
      }

      .badges {
        display: flex;
        gap: 20px;
        padding-top: 15px;

        .badge {
          display: flex;
          p {
            font-size: 12px;
            padding-left: 5px;
            font-weight: 500;
            color: #01989f;
            text-transform: uppercase;
          }
        }
      }

      .details {
        padding: 20px 0;
        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 1.71;
          word-break: break-all;
        }
      }

      .links {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-right: 50px;
        justify-content: space-between;
        .modifyInfoLink {
          font-size: 12px;
          font-weight: normal;
          color: #191919;
          cursor: pointer;

          p {
            text-decoration: underline;
            // border-bottom: 1px solid #000;
            width: fit-content;
          }
        }
      }
    }

    .subscribeBtn {
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      border-radius: 8px;
      color: #fff;
      cursor: pointer;
      text-transform: uppercase;

      img {
        padding-left: 5px;
        margin-right: -10px;
      }
    }

    .profilepicWrapper {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      justify-content: center;

      .profilepic {
        width: fit-content;
        display: inline-block;
        border-radius: 50%;
        padding: 8px;
        background: linear-gradient(135deg, #fff253, #99e67b 50%, #01989f);

        img {
          height: 90px;
          width: 90px;
          display: block;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .userWithNewStory {
        padding: 8px;
        background: linear-gradient(135deg, #fff253, #99e67b 50%, #01989f);
      }

      .actions {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        img {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .socailCounts {
    padding-top: 30px;

    .countsWrapper {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
      // justify-content: center;
      .counts {
        width: 50%;
        border-radius: 8px;
        background-color: #f5f7f8;
        padding: 8px 20px;
        text-align: center;
        cursor: pointer;

        h3 {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          padding-bottom: 5px;
        }

        p {
          opacity: 0.4;
          font-size: 12px;
          margin: 0;
        }
      }

      .Subscribers {
        display: flex;
        width: 100%;
        gap: 20px;
      }
    }
  }

  .deleteStoryIcon {
    position: absolute;
    top: 30px;
    right: 155px;
    cursor: pointer;
  }
}

.themestitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.4px;
}

.themes {
  margin-bottom: 20px;
  margin-top: 5px;

  display: flex;
  flex-wrap: wrap;
  color: #fff;
  gap: 10px;
  // p {
  //   cursor: pointer;
  //   margin: 0;
  //   border-radius: 4px;
  //   background-color: #00565b;
  //   padding: 7px 10px;
  //   font-size: 12px;
  //   font-weight: 500;
  // }

  .themeFiltratin {
    // margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .selector {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      align-items: center;

      .selectorItem {
        .selectorItemRadio {
          appearance: none;
          display: none;
        }
        .selectorItemLabel {
          cursor: pointer;
          text-align: center;
          background-color: var(--accecent-color);

          color: #fff;
          // border: solid 1.5px var(--bg-color);
          padding: 5px;
          padding-inline: 10px;
          border-radius: 10px;
          transform: none;
          font-size: 14px;
          // color: #000;
        }
        .selectorItemRadio:checked + .selectorItemLabel {
          background-color: var(--bg-color);
          color: #fff;
        }
        @media (max-width: 480px) {
          .selector {
            width: 90%;
          }
        }
      }
    }
  }
}

.actionsMobileView {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .bio {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .profilePicWrapper {
      margin: 40px 0;
      display: flex;
      justify-content: center;
    }

    .profilepicWrapper {
      padding: 20px 0;

      .actions {
        display: none !important;
      }
    }

    .userInfo {
      padding-top: 20px;
      padding-left: 0px !important;
      .badges {
        align-items: center;
        justify-content: center;
      }

      .nameRated {
        padding-left: 17px;
      }

      .userNameRated {
        padding-right: 5px;
      }

      .links {
        margin-inline: 50px;
        justify-content: center !important;
      }

      .actionsMobileView {
        padding-top: 20px;
        display: flex;
        justify-content: center;
        gap: 20px;
        padding-bottom: 20px;
        img {
          height: 20px;
          width: 20px;
          cursor: pointer;
        }
      }
    }

    .modifyInfoLink {
      display: flex;
      justify-content: center;
    }
  }

  .deleteStoryIcon {
    position: absolute;
    top: 30px;
    right: 30px !important;
    cursor: pointer;
  }

  .selectorItemLabel {
    text-align: start !important;
  }

  .countsWrapper {
    flex-direction: column;

    .counts {
      width: 100% !important;
    }
  }
}
