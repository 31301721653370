.usersWrapper {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  color: black;
  // margin-bottom: 5px;
  // border-radius: 12px;
  // box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  // background-color: #fff;
  cursor: pointer;

  .profilepic {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }

  .hashtag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 57px;
    width: 57px;
    border-radius: 50%;
    border: 1px solid rgba(25, 25, 25, 0.16);

    // font-size: 24;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 10px;
    .name {
      font-size: 16px;
    }

    .fullname {
      font-size: 14px;
      color: gray;
    }
  }

  .icon {
    padding: 0px 5px;
  }
}

.commentsWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-inline: 10px;

  p {
    padding-left: 0px;
  }
}
