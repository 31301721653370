.chatBotContainer {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  //   padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chatBotHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--bg-color);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
    padding: 10px 20px;

    .logoContainer {
      display: flex;
      align-items: center;

      img {
        width: 50px;
      }

      h6 {
        padding-left: 8px;
        color: #99e67b;
        font-weight: normal;
        font-size: 22px;
      }
    }

    .closeIcon {
      cursor: pointer;
    }
  }

  .chatBotBody {
    width: 100%;
    min-height: 60vh;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background-color: #f5f7f8;
    padding: 20px 10px;
    position: relative;

    .scrollableContent {
      overflow: auto;
      height: 45vh;
      .date {
        font-size: 12px;
        color: #191919;
        text-align: center;
      }

      .chating {
        padding: 20px 7px;
        .question {
          display: flex;
          align-items: end;
          justify-content: end;
          margin-bottom: 10px;

          img {
            height: 25px;
          }

          p {
            margin-right: 5px;
            padding: 5px;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            background-color: lightgray;

            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
        }

        .answer {
          display: flex;
          align-items: end;
          justify-content: start;
          margin-bottom: 10px;

          img {
            height: 25px;
          }

          p {
            margin-left: 5px;
            padding: 5px;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            background-color: lightgray;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        .select {
          .heading {
            padding: 5px;
            width: fit-content;
            margin-top: 10px;
            margin-left: 30px;
            font-size: 12px;
            background-color: lightgray;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            border-bottom-left-radius: 6px;

            img {
              height: 20px;
            }
          }

          .option {
            width: fit-content;
            margin: 10px 0;
            margin-left: 30px;
            padding: 5px;
            font-size: 12px;
            font-weight: 500;
            border: 1px solid var(--red-bg);
            color: var(--red-bg);
            border-radius: 6px;
            cursor: pointer;
          }
        }
      }
    }

    .messageInput {
      position: absolute;
      bottom: 15px;
      width: 95.9%;
      border-radius: 22px;
      border: solid 1px rgba(25, 25, 25, 0.16);
      background-color: #fff;
      padding: 7px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      input,
      textarea {
        font-size: 14px;
        color: #191919;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .chatBotBody {
    min-height: 72vh !important;
    .scrollableContent {
      overflow: auto;
      height: 60vh !important;
    }
  }
}
