.mentions {
  margin: 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-size: 12px !important;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 43px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  padding: 2px 0;
  margin-left: -1px;
  color: var(--accecent-color);
  background-color: white;
  // text-shadow: 1px 1px 1px #f5f7f8, 1px -1px 1px #f5f7f8, -1px 1px 1px #f5f7f8,
  //   -1px -1px 1px #f5f7f8;
  pointer-events: none;
}

.mentions__mention::selection {
  background-color: green;
  color: white;
}

.repliesInput {
  margin-top: 10px;
  margin-left: 4% !important;
}

.writeComment {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;

  img {
    border-radius: 50%;
  }

  .textareaWrapper {
    width: 81%;

    textarea {
      border-radius: 12px;
      border: solid 1px rgba(25, 25, 25, 0.12);
      outline: none;
      padding: 10px;
      padding-right: 30px;
      font-size: 12px !important;
      resize: none;
      width: 100%;
      margin: 20px;
      overflow: hidden;
    }

    input {
      outline: none;
      font-size: 14px !important;
      resize: none;
      width: 100%;
      margin-left: -10px;
      overflow: hidden;
    }
  }

  .textareaWrapperForCommentReplies {
    width: 83%;
    margin-left: -20px;
    textarea {
      border: none;
      outline: none;
      font-size: 12px !important;
      resize: none;
      width: 100%;
      overflow: hidden;
    }

    textarea::selection {
      background-color: #e2e1e1;
      color: var(--accecent-color);
    }

    input {
      outline: none;
      font-size: 14px !important;
      resize: none;
      width: 100%;
      margin-left: -10px;
      overflow: hidden;
    }
  }

  .emogie,
  .replyEmogie {
    cursor: pointer;
    position: absolute;

    img {
      height: 17px;
      width: 17px;
    }
  }

  .emogie {
    bottom: 10px;
    right: 65px;
  }

  .replyEmogie {
    // bottom: 10px;
    right: 40px;
  }
  .emogiePicker {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 60px;
    top: 60px;
    z-index: 1000;
  }

  .sendCommntBtn {
    padding-right: 20px;
    border-radius: 50%;
    padding: 5px 8px;
    background-color: rgb(226, 225, 225);

    img {
      height: 18px;
      width: 18px;
    }
  }

  .limitErrorMsg {
    position: absolute;
    bottom: 0px;
    left: 50px;
  }
}

.commentsSortWrapper {
  padding: 15px 0;
}

.tagsPeopleListWrapper {
  position: relative;

  ul {
    position: absolute;
    background-color: white;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    padding: 0;
    z-index: 10;
    top: -20px;
    left: 50px;
    margin: 0;
    list-style: none;
    li {
      padding: 2px 20px;
      width: 100%;
      cursor: pointer;
      border-radius: 8px;
      font-size: 13px;
      &:hover {
        background-color: lightgrey;
      }
    }
  }
}

.commentReplyContainer {
  border-left: 2px solid #c4e3e6;
  margin-left: 117px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px 0;
  .commentReplyWrapper {
    margin-left: 15px;
  }
}

.commentWrapper {
  display: flex;
  flex-direction: column;
  // gap: 20px;
  margin-bottom: 2rem;
  margin-left: 80px;
}

.commentWrapper,
.commentReplyWrapper {
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 10px 20px;
  background-color: #fff;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftSide {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
      }

      .nameDate {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 15px;
        h2 {
          font-size: 14px;
          font-weight: 600;
          color: #191919;
        }

        p {
          padding-top: 0.1rem;
          padding-left: 0.5rem;
          font-size: 11px;
          opacity: 0.72;
          min-width: fit-content;
        }
      }
    }

    .rightside {
      display: flex;
    }
  }

  .content {
    padding: 0;
    padding-left: 40px;
    opacity: 0.72;
    font-size: 14px;
    line-height: 1.71;
  }

  .editableContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .textareaWrapper {
      width: 85%;
      textarea {
        padding: 10px;
        opacity: 0.72;
        font-size: 12px;
        line-height: 1.71;
        width: 90%;
        top: -2px !important;
      }
    }

    .textareaWrapperForCommentReplies {
      width: 70%;
      textarea {
        padding: 10px;
        opacity: 0.72;
        font-size: 12px;
        line-height: 1.71;
        width: 90%;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bottomLeft {
      display: flex;
      align-items: center;
      gap: 10px;

      .reactionsWrapper {
        display: flex;
        width: fit-content;
        align-items: center;

        button {
          padding: 5px;
          border-radius: 16px;
          color: #d5d5d5;
          display: flex;
          align-items: center;

          .like {
            margin-bottom: 3px;
          }

          .dislike {
            margin-bottom: 1px;
          }
        }
        button:hover {
          background-color: #d5d5d5;
          color: #fff;
        }

        .comment {
          display: flex;
          align-items: center;
          span {
            padding-left: 5px;
          }
        }

        .activeComment {
          background-color: #d5d5d5;
          color: #fff;
        }

        span {
          color: black;
          font-size: 14px;
        }
      }

      .selector {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 5px;
        width: 100%;
        align-items: center;

        .selectorItem {
          label {
            p {
              padding-left: 5px;
            }
          }
          .selectorItemRadio {
            appearance: none;
            display: none;
          }
          .selectorItemLabel {
            cursor: pointer;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 5px;
            // padding-inline: 10px;
            border-radius: 20px;
            transform: none;
            font-size: 14px;
          }

          .selectorItemLabel:hover {
            background-color: #d5d5d5;
          }
          .selectorItemRadio:checked + .selectorItemLabel {
            background-color: #d5d5d5;
            // color: #fff;
          }
          @media (max-width: 480px) {
            .selector {
              width: 90%;
            }
          }
        }
      }

      .respond {
        padding-left: 20px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.43;
        color: #191919;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    @media (max-width: 480px) {
      .bottomLeft {
        gap: 5px !important;
        .respond {
          padding-left: 0px;
        }
      }
    }

    .bottomRight {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding-left: 10px;
      .longRep,
      .shortRep {
        padding-left: 8px;
        font-size: 14px;
        color: #191919;
      }

      .shortRep {
        display: none;
      }
    }

    @media (max-width: 480px) {
      .bottomRight {
        padding-left: 5px !important;
        .longRep {
          padding-left: 5px;
          display: none;
        }

        .shortRep {
          display: block !important;
        }
      }
    }
  }
}

.DisplayStoriesContainer {
  position: relative;

  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 40px;
  background-color: #fff;

  .storyHeader {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .storyHeaderLeft {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 14px;
        font-weight: normal;
        opacity: 0.72;
        margin: 0;
        text-align: end;
      }
      .theme {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        letter-spacing: 0.6px;
        color: var(--accecent-color);
        text-align: start;
        text-transform: uppercase;
      }
    }

    .storyHeaderRight {
      .heading {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

  .storyBody {
    .storyContent {
    }
    p {
      font-size: 14px;
      font-weight: normal;
      opacity: 0.72;
      margin: 0;
      overflow-wrap: break-word;
    }

    .contentBtn {
      color: var(--accecent-color);
      cursor: pointer;
      padding-left: 5px;
    }

    .details {
      padding-bottom: 10px;
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icons {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;

        .icon {
          cursor: pointer;
        }

        .commentsWrapper {
          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            padding-left: 5px;
          }
        }
      }
    }

    .prefessionalResourcesWrapper {
      display: flex;
      justify-content: space-between;
      // gap: 40px;
      margin-inline: 10%;
      text-align: center;

      div {
        cursor: pointer;
      }

      h6 {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 400;
        opacity: 0.72;
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .beforeAfterWrapper {
      display: flex;
      justify-content: center;
      text-align: center;
      div {
        cursor: pointer;
      }
    }
  }

  .smileysWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-right: 30px;
    .icons {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;

      .icon {
        cursor: pointer;
      }

      .commentsWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          padding-left: 5px;
        }
      }
    }
  }

  .showReactions {
    position: absolute;
    bottom: 45px;
    right: 20px;
    z-index: 100;
  }

  .reactionsContainer {
    position: absolute;
    right: 30px;
    bottom: -30px;
    display: flex;
    // justify-content: flex-end;
    width: fit-content;
    align-items: center;
    gap: 0.5%;
    border-radius: 24px;
    border: solid 2px rgba(25, 25, 25, 0.12);
    background-color: #f6f6f6;
    padding: 5px;
    // padding-right: 15px;

    .themeFiltratin {
      display: flex;
      justify-content: center;
      align-items: center;

      .selector {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1px;
        width: 100%;
        align-items: center;

        .selectorItem {
          // label {
          //   p {
          //     padding-left: 5px;
          //   }
          // }
          .selectorItemRadio {
            appearance: none;
            display: none;
          }
          .selectorItemLabel {
            cursor: pointer;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 5px;
            // padding-inline: 10px;
            border-radius: 20px;
            transform: none;
            font-size: 14px;
          }

          .selectorItemLabel:hover {
            background-color: #d5d5d5;
          }
          .selectorItemRadio:checked + .selectorItemLabel {
            background-color: #d5d5d5;
            // color: #fff;
          }
          @media (max-width: 480px) {
            .selector {
              width: 90%;
            }
          }
        }
      }
    }
  }
}

.ReplyloaderContainer {
  margin-left: 25%;
}

.CommentloaderContainer {
  margin-left: 15%;
}

.commentsWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    padding-left: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .commentReplyContainer {
    margin-left: 25px;
  }
  .repliesInput {
    margin-left: 5%;
  }
  .commentReplyWrapper {
    margin-left: 40px;
  }
  .commentWrapper {
    margin-left: 0px;
  }
  .details {
    flex-direction: column;
    align-items: start !important;
    justify-content: start;

    .icons {
      margin-bottom: 10px;
    }
  }

  .storyHeaderLeft {
    flex-direction: column;
    align-items: start !important;

    .date {
      display: flex;
      padding-bottom: 20px;
    }
  }

  .reactionsContainer {
    right: 10px !important;
    left: 20px !important;
  }

  .ReplyloaderContainer {
    margin-left: 13%;
  }

  .CommentloaderContainer {
    margin-left: 0%;
  }

  .emogiePicker {
    right: 0px !important;
    top: 70px !important;
  }
}
