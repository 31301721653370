/* StripeComponent.css */
.stripe-form {
  width: 300px;
  margin: auto;
}

.card-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.pay-button {
  background-color: var(--customButton-bg);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.loaderWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 14px 15px;
  padding-bottom: 13px;
  background-color: var(--customButton-bg);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
