.ModalBodyHeader {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}

.resourceSuccessWrapper {
  border-radius: 8px;
  background-color: #01989f;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;

  img {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-style: normal;
    line-height: 1.71;
    padding-top: 10px;
    .email {
      font-weight: 600;
      color: #99e67b;
    }
  }

  .doneButton {
    background-color: transparent;
    border-radius: 4px;
    border: solid 2px #fff;
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.1;
    margin-inline: 15px;
  }
}
