.header {
  display: flex;
  justify-content: space-between;
  align-items: start;

  h5 {
    font-size: 16px;
  }
}

.body {
  padding-top: 20px;
  .searchContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.16);
    padding: 7px 10px;
    width: 100%;
    input {
      padding-left: 10px;
      width: 100%;
      outline: none;
      border: none;
      font-style: italic;
    }
  }

  .usersContainer {
    padding-top: 20px;
    height: 400px;
    overflow-x: auto;
    .userWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;

      .contentContainer {
        display: flex;
        align-items: center;
        .imgWrapper {
          height: 30px;
          width: 30px;
          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }

        .content {
          padding-left: 15px;
          .name {
            font-size: 14px;
          }

          .userName {
            font-size: 12px;
          }
        }
      }

      .actionContainer {
        padding-right: 5px;
        .actionbtn {
          background-color: var(--accecent-color);
          border: 2px solid var(--accecent-color);
          color: white;
          padding: 2px;
          width: 80px;
          border-radius: 8px;
          cursor: pointer;

          p {
            font-size: 14px;
          }
        }

        .actionbtnSent {
          background-color: transparent;
          color: black;
        }
      }
    }
  }
}
