.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.11;
    color: #191919;
    margin: 0;
    text-align: center;
  }
}

.body {
  .text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    color: #000000;
    padding-inline: 30px;
    padding-top: 20px;
  }

  .annuler {
    color: #01989f;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    p {
      border-bottom: 2px solid #01989f;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
