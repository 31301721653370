.links {
  li {
    a {
      color: blue;
    }
  }
}

.super-container {
  display: flex;
  justify-content: center;
  align-content: center;
  h5 {
    font-size: 18px;
    font-weight: 700;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  // h6 {
  //   font-weight: 700;
  // }
  a {
    display: inline;
  }
  p {
    // text-align: justify;
  }
}

.heading {
  color: var(--accecent-color);
  font-size: 24px;
  font-weight: 600;
}

.subtitleL1 {
  font-weight: 600;
}

.paragraph {
  padding: 15px 0;
}

.sectionHead {
  padding: 10px 20px;
  cursor: pointer;
  width: 100% !important;
  background-color: #01979f1a;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  margin-top: 0px;
  gap: 10px;
  h6,
  h5 {
    margin: 0;
  }
}

.cookiesBorderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    border: 1px solid black;
    width: 70%;
    padding: 10px;
  }

  .cookiesToggle {
    padding-right: 50px;
  }
}

.backButton {
  margin: 2rem 0;
  max-width: fit-content;
  align-self: flex-end;
}
.cookiesCollapse {
  list-style: none;
  padding: 10px 0 !important;
  padding-top: 0px !important;
  margin: 0;
}

.dashaedUL {
  list-style-type: disc;
  padding-bottom: 15px;
  // text-align: justify;
  // padding-bottom: 10px;

  // li::before {
  //   content: "-";
  //   margin-right: 0.5em;
  // }
}
