.loginModalBody {
  .loginModalBodyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #191919;
      margin: 0;
    }
  }

  .resourceName {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: var(--accecent-color);
    text-transform: uppercase;
    padding-bottom: 20px;
  }

  p {
    opacity: 0.72;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
  }
  .loginForm {
    .inputContainer {
      display: flex;
      flex-direction: column;
      // margin-bottom: 5px;

      label {
        font-size: 12px;
        font-weight: 600;
        color: #191919;
      }

      .inputEyeWrapper {
        border-radius: 4px;
        border: solid 1px #191919;
        // margin: 10px 0;
        padding: 5px 10px;
        height: 40px;
        display: flex;
        justify-content: space-between;

        input {
          outline: none;
          width: 90%;
          font-size: 12px;
        }

        button {
          padding-right: 10px;
        }
      }
    }

    .submitButton {
      color: #fff !important;
      width: 100%;
      display: flex;
      margin-inline: auto;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
}
