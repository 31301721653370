.sidebarRight {
  width: 300px;
  height: 100%;
  position: sticky;
  top: 40px;

  .autres {
    text-align: center;
    margin-top: 20px;
    // width: 50%;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    a {
      color: #01989f;
    }
    p {
      border-bottom: 2px solid #01989f;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .public {
    margin-top: 70px;
    border-radius: 10px;
    padding: 20px;
    opacity: 0.12;
    color: black;
    height: 40vh;
    background-color: #191919;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .sidebarRight {
    display: none;
  }
}
