.professionalSignUpContainer {
  background-color: var(--bg-color);
  color: white;
  text-align: center;
  border-radius: 10px;
  padding: 20px;

  .imageWrapper {
    height: auto;
    width: auto;
    margin-bottom: 20px;
    img {
      height: 100%;
      width: 100%;
    }
  }

  .description {
    font-family: 'Kalam', cursive;
    // font-weight: 800;
    // margin: 20px 0;
    padding: 10px;
    padding-bottom: 0px;
    // border-radius: 20px;
    // border: 10px solid var(--logo-color);
    // background-color: white;
    // color: var(--bg-color);
  }

  .info {
    background-color: var(--logo-color);
    color: var(--bg-color);
    border-radius: 50%;
    padding: 0 11px;
    padding-bottom: 2px;
    cursor: pointer;
  }

  .boxDescription {
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    margin: 20px 0;
    padding: 10px;
    border-radius: 20px;
    border: 10px solid var(--red-bg);
    background-color: white;
    color: var(--bg-color);
  }

  .button {
    margin-inline: auto;
    padding: 10px 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    background-color: var(--red-bg);
    border-radius: 50px;
    width: fit-content;
    cursor: pointer;
  }
}

// Info Modal
.infoModal {
  text-align: center;
  font-family: 'Kalam', cursive;
  p,
  h6 {
    color: var(--bg-color);
    font-style: italic;
    font-weight: 600;
    padding-bottom: 10px;
    span {
      color: var(--accecent-color);
    }
  }

  h6 {
    color: var(--accecent-color);
    padding-bottom: 0px;
  }
}
