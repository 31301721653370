.subscriptionsContainer {
  .mySubscriptions {
    position: relative;
    padding: 15px 20px;
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;

    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
    }

    .mySubscriptionsStoriesListWrapper {
      margin-top: 20px;
      .mySubscriptionsStoriesList {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 18px;

        .allSubscriber {
          margin-top: -18px;
          cursor: pointer;
          .imgContainer {
            background-color: #f5f7f8;
            padding: 16px;
            border-radius: 50%;
            margin-top: 20px;
            margin-bottom: 1px;
            border: 3px solid transparent;

            img {
              height: 31px;
              width: 31px;
            }
          }
        }

        .selectedAllSubscriber {
          .imgContainer {
            border: 3px solid var(--red-bg);
            // padding: 14px;
            // img {
            //   height: 35px;
            //   width: 35px;
            // }
          }

          h4 {
            color: var(--red-bg) !important;
          }
        }

        .subscriber {
          display: flex;
          flex-direction: column;
          align-items: center;

          .picWrapper {
            display: inline-block;
            border-radius: 50%;
            width: 72px;
            height: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(151, 151, 151, 0.37);
            div {
              background: white;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 67px;
              height: 67px;
              border-radius: 50%;
            }
            cursor: pointer;
            img {
              height: 62px;
              width: 62px;
              border-radius: 50%;
            }
          }

          .userName {
            cursor: pointer;
          }

          h4,
          p {
            font-weight: 500;
            line-height: 1.78;

            // text-align: center;
            color: #191919;
          }

          h4 {
            text-align: center;
            font-size: 10px;
            padding-top: 8px;
            text-transform: uppercase;
          }

          p {
            font-size: 9px;
          }
        }

        .selectedSubscriber {
          .picWrapper {
            background: var(--red-bg) !important;
          }
          h4 {
            color: var(--red-bg);
          }
        }

        .userWithNewStory {
          .picWrapper {
            background: linear-gradient(135deg, #fff253, #99e67b 50%, #01989f);
          }
        }
      }
    }

    .mySubscriptionsListWrapper {
      margin-top: 20px;
      .mySubscriptionsList {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 18px;

        .subscriber {
          display: flex;
          flex-direction: column;
          align-items: center;

          .picWrapper {
            display: inline-block;
            border-radius: 50%;
            width: 72px;
            height: 72px;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: rgba(151, 151, 151, 0.37);
            div {
              // background: white;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 67px;
              height: 67px;
              border-radius: 50%;
            }
            cursor: pointer;
            img {
              height: 62px;
              width: 62px;
              border-radius: 50%;
            }
          }

          .userName {
            cursor: pointer;
          }

          h4,
          p {
            font-weight: 500;
            line-height: 1.78;

            // text-align: center;
            color: #191919;
          }

          h4 {
            text-align: center;
            font-size: 10px;
            padding-top: 8px;
            text-transform: uppercase;
          }

          p {
            font-size: 9px;
          }
        }

        .selectedSubscriber {
          .picWrapper {
            background: var(--red-bg) !important;
          }
          h4 {
            color: var(--red-bg);
          }
        }

        .userWithNewStory {
          .picWrapper {
            background: linear-gradient(135deg, #fff253, #99e67b 50%, #01989f);
          }
        }
      }
    }

    .backArrowWhite,
    .backArrowWhiteActive,
    .nextArrowWhite,
    .nextArrowWhiteActive {
      padding: 4px;
      border-radius: 4px;
      position: absolute;
      box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
      top: 50%;
      cursor: pointer;
    }

    .backArrowWhiteActive {
      left: -15px;
      background-color: var(--red-bg) !important;
    }

    .nextArrowWhiteActive {
      right: -13px;
      background-color: var(--red-bg);
    }

    .backArrowWhite {
      left: -15px;
      background-color: rgba(0, 60, 63, 0.12);
    }

    .nextArrowWhite {
      right: -13px;
      background-color: rgba(0, 60, 63, 0.12);
    }
  }

  .suggestedSubscriptions {
    position: relative;
    padding: 13px;
    border-radius: 12px;
    border: solid 2px rgba(151, 151, 151, 0.16);
    margin-top: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.2;

        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .content {
      .themeWrapper {
        margin-top: 20px;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 1.5;
          padding-bottom: 10px;
        }

        .theme {
          display: flex;
          align-items: start;
          text-align: center;
          flex-wrap: nowrap;
          overflow-y: hidden;
          gap: 18px;
          -webkit-overflow-scrolling: touch;

          .subscriber {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              border-radius: 50%;
              cursor: pointer;
            }
            h4,
            p {
              font-weight: 500;
              line-height: 1.78;
              color: #191919;
            }

            h4 {
              font-size: 10px;
              padding-top: 8px;
              text-transform: uppercase;
            }

            p {
              font-size: 9px;
            }
          }
        }

        .theme::-webkit-scrollbar {
          width: 0em;
          height: 0em;
        }

        .theme::-webkit-scrollbar-track {
          background: transparent;
        }

        .theme::-webkit-scrollbar-thumb {
          background: transparent;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .subscriptionsContainer {
    .mySubscriptions {
      .mySubscriptionsStoriesListWrapper {
        .mySubscriptionsStoriesList {
          flex-wrap: nowrap;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
        }
        .mySubscriptionsStoriesList::-webkit-scrollbar {
          width: 0em;
          height: 0em;
        }

        .mySubscriptionsStoriesList::-webkit-scrollbar-track {
          background: transparent;
        }

        .mySubscriptionsStoriesList::-webkit-scrollbar-thumb {
          background: transparent;
        }
      }

      .mySubscriptionsListWrapper {
        .mySubscriptionsList {
          flex-wrap: nowrap;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
        }
        .mySubscriptionsList::-webkit-scrollbar {
          width: 0em;
          height: 0em;
        }

        .mySubscriptionsList::-webkit-scrollbar-track {
          background: transparent;
        }

        .mySubscriptionsList::-webkit-scrollbar-thumb {
          background: transparent;
        }
      }

      .backArrowWhite,
      .nextArrowWhite,
      .backArrowWhiteActive,
      .nextArrowWhiteActive {
        display: none;
      }
    }
  }
}
