.buttonOutline {
  // border: 2px solid white;
  text-align: center;
  border-style: solid;
  padding: 15px;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }
}
