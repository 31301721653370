.main-body-container {
  max-width: 1500px;
  margin-inline: auto;
}

.main-body-container .App {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-inline: 50px;
  width: 100%;
  position: relative;
}

.assitentIcon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.assitentIcon:hover {
  transform: scale(1.1);
}

.innerComponents {
  width: 45%;
  margin-inline: auto;
}

.innerComponentsWidthChnage {
  width: 55%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main-body-container .App {
    justify-content: center;
    display: flex;
    padding: 0;

    margin-top: 100px;
  }

  .innerComponents {
    width: 100%;
    padding-inline: 20px;
  }
}
