.mainContainer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imagesContainer {
    width: 300px;
    overflow-x: scroll;
    .close {
      float: inline-end;
      padding-bottom: 10px;
    }
    .imagesWrapper {
      width: 500px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;

    .downloadButton {
      border-bottom: 2px solid white;
      cursor: pointer;
    }
  }
}
