.appointmentContainer {
  .header {
    background-color: var(--accecent-color);
    color: white;
    padding: 10px;
    margin: -20px;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: start;

    .back {
      cursor: pointer;
      svg {
        g {
          fill: white;
        }
      }
    }

    .profilepic {
      height: 100%;
      margin-inline: 10px;
      border-radius: 50%;
      overflow: hidden;
      img {
        height: 45px;
        width: 45px;
        border-radius: 50%;
      }
    }

    .content {
      .name {
        font-size: 14px;
        font-weight: 300;
      }

      .desc {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .body {
    margin-top: 40px;
    .title {
      font-size: 14px;
      font-weight: 600;
      padding-bottom: 20px;
    }

    .showMoreAvailability {
      border: 2px solid var(--accecent-color);
      border-radius: 10px;
      color: var(--accecent-color);
      padding: 10px;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: var(--accecent-color);
        color: white;
      }
    }
  }

  .detailTop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addressHeading {
      font-weight: bold;

      span {
        img {
          padding-bottom: 2px;
        }
      }
    }

    a {
      color: var(--accecent-color) !important;
      cursor: pointer;
      font-size: 14px !important;
    }
  }

  .detailBottom {
    p {
      font-size: 14px;
    }

    .expertiesWrapp {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      gap: 10px;
      padding-top: 10px;
      p {
        padding: 8px 15px;
        border-radius: 22px;
        background-color: var(--accecent-color);
        color: white;
        width: fit-content;
        cursor: pointer;
      }
    }

    .dateWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      padding-top: 10px;
      justify-content: center;
      align-items: center;

      .date {
        width: 100%;
        max-width: 300px;
        box-sizing: border-box;
        padding: 10px 20px;
        text-align: center;
        border-radius: 5px;
        background-color: #01979fd7;
        color: white;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          background-color: var(--accecent-color);
        }
      }
    }

    hr {
      margin-inline: -15px;
    }

    .moreButton {
      text-align: center;
      width: fit-content;
      margin-inline: auto;
      font-weight: 600;
      color: var(--accecent-color);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 750px) {
  .header {
    margin: -20px !important;
  }
}
