.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .searchContainer {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.16);
    background-color: #fff;
    padding: 7px 10px;
    width: 88%;
    input {
      padding-left: 10px;
      width: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      font-style: italic;
    }
  }

  .searchContainer2 {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.16);
    background-color: #fff;
    padding: 7px 10px;
    width: 100%;
    input {
      padding-left: 10px;
      width: 100%;
      background-color: transparent;
      outline: none;
      border: none;
      font-style: italic;
    }
  }

  .filterIcon {
    padding: 8px 10px;
    border-radius: 8px;
    background-color: #01989f;
    float: inline-end;
    cursor: pointer;
    margin-left: 10px;
  }
}

.byFilter {
  padding-top: 20px;
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    gap: 10px;
    li {
      border-radius: 16px;
      border: solid 1.5px #d5d5d5;
      padding: 5px 10px;
      a {
        text-decoration: none;
        font-size: 14px;
        color: #d5d5d5;
      }
    }

    li:hover {
      background-color: var(--bg-color);
    }

    .active {
      background-color: #01989f;
    }
  }
}

.themeCover {
  display: "flex";
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 20px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);

  border-radius: 12px;
  background-color: #fff;

  img {
    max-width: 100%;
  }
}

.themeFiltratin {
  gap: 8px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  button {
    border: solid 1.5px #d5d5d5;
    color: rgba(0, 0, 0, 0.7);
    padding: 7px;
    padding-inline: 10px;
    border-radius: 20px;
    transform: none;
    font-size: 14px;
  }
  button:hover {
    background-color: var(--bg-color);
    color: #fff;
  }

  .activeResource {
    background-color: var(--bg-color);
    color: #fff;
  }
}

.themeButton {
  background-color: var(--customButton-bg);
  text-align: center;
  padding: 15px 15px;
  border-radius: 4px;
  cursor: pointer;
  width: 70%;
  margin-inline: auto;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }

  svg {
    fill: white;
    height: 25px;
  }
}

.themeButton:hover {
  background-color: var(--customButton-bgHover);
  color: white;
}

.paragraph {
  padding: 10px 0;
}

.cookiesBorderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    border: 1px solid black;
    width: 70%;
    padding: 10px;
  }

  .cookiesToggle {
    padding-right: 50px;
  }
}
.pMarginBottom {
  margin-bottom: 10px !important;
}

@media only screen and (max-width: 768px) {
  .themeFiltratin {
    justify-content: flex-start !important;
  }
  .selector {
    justify-content: flex-start !important;
  }

  .byFilter {
    ul {
      justify-content: start !important;
    }
  }

  .themeButton {
    width: 100%;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 448px) {
  .filterTime {
    padding: 5px 7px !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 400px) {
  .filterTime {
    padding: 5px 7px !important;
    font-size: 10px !important;
  }
}
