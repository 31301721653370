.customBackdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}
.ModalBody {
  overflow: hidden;
  padding: 20px 0;
  padding-right: 20px;
  .ModalBodyHeader {
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px !important;

    h5 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }

    img {
      height: 25px;
      width: 30px;
    }

    button {
      .close {
        font-size: 30px !important;
        color: #ffff;
      }
    }
  }

  p {
    opacity: 0.72;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
  }
  .themesWrapper {
    height: 485px;
    overflow-y: auto !important;
    .content {
      padding: 10px 20px;
      display: flex;
      align-items: center;

      img {
        color: #fff !important;
      }

      p {
        padding-left: 20px;
        color: #ffff;
      }
    }
    .content:hover {
      background-color: var(--bg-color);
      cursor: pointer;
    }
  }
}

.themes {
  margin: 20px 0;
  padding-right: 15px;
  overflow-y: auto !important;
  height: 474px;
  // display: flex;
  // flex-wrap: wrap;
  color: #fff;
  gap: 10px;
  // p {
  //   cursor: pointer;
  //   margin: 0;
  //   border-radius: 4px;
  //   background-color: #00565b;
  //   padding: 7px 10px;
  //   font-size: 12px;
  //   font-weight: 500;
  // }

  .themeFiltratin {
    // margin: 20px 0;
    // display: flex;
    // justify-content: center;
    align-items: center;
    overflow-y: auto !important;

    .selector {
      // display: flex;
      // justify-content: center;
      // flex-wrap: wrap;
      overflow-y: auto !important;
      gap: 10px;
      width: 100%;
      align-items: center;

      .selectorItem {
        overflow-y: auto !important;
        border-bottom: 1px solid #b8b8b869;
        .selectorItemRadio {
          appearance: none;
          display: none;
        }
        .selectorItemLabel {
          display: flex;
          align-items: center;

          cursor: pointer;
          text-align: center;
          background-color: var(--accecent-color);
          color: #fff;
          // border: solid 1.5px var(--bg-color);
          padding: 15px;
          padding-inline: 10px;
          padding-left: 20px;
          // border-radius: 10px;
          transform: none;
          font-size: 14px;
          // color: #000;
          p {
            padding-left: 20px;
            // white-space: pre-wrap;
            // word-wrap: break-word;
            text-align: start;
          }
        }
        .selectorItemRadio:checked + .selectorItemLabel {
          background-color: var(--bg-color);
          color: #fff;
        }
        @media (max-width: 480px) {
          .selector {
            width: 90%;
          }
        }
      }
    }
  }
}

.activeConversation {
  background-color: var(--bg-color) !important;
}

@media (max-width: 768px) {
  .ModalBody {
    height: 100%;
  }

  .themes {
    height: 580px !important;
    margin-bottom: 20px !important;
  }
  .themesWrapper {
    height: 100% !important;
  }
}
