.allPersonalitiesContainer {
  position: relative;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 40px;
  background-color: #fff;

  .body {
    h1,
    h2 {
      font-weight: 600;
      letter-spacing: normal;
    }

    h1 {
      text-transform: uppercase;
      font-size: 18px;
      line-height: 24px;
      color: #191919;
    }

    h2 {
      font-size: 14px;
      line-height: 14px;
      color: #000000;
    }
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: normal;
      margin: 0;
      padding-top: 5px;
      padding-bottom: 10px;
      overflow-wrap: break-word;
    }

    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 10px;

        .favIcon {
          margin-bottom: 7px;
        }
        .favStarIcon {
          margin-bottom: 5px;
          cursor: pointer;
        }
        .icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          svg {
            height: 18px;
          }
        }

        .commentsWrapper {
          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            padding: 0;
            padding-left: 5px;
          }
        }
      }
    }
  }

  .reactionsWrapper {
    position: absolute;
    right: 30px;
    bottom: -30px;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 24px;
    border: solid 1px rgba(25, 25, 25, 0.12);
    background-color: #f6f6f6;
    padding: 3px;
    > div:nth-last-child(2) {
      margin-left: 4px;
    }
    button {
      padding: 5px;
      border-radius: 16px;
      color: #d5d5d5;
    }
    button:hover {
      background-color: #d5d5d5;
      color: #fff;
    }

    .activeReaction {
      background-color: #d5d5d5;
      color: #fff;
    }

    span {
      color: black;
      font-size: 14px;
    }

    .tooltipContainer {
      position: relative;
      display: inline-block;
    }

    .tooltipText {
      visibility: hidden;
      width: fit-content;
      white-space: nowrap;
      background-color: #01989f;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltipText::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: #01989f transparent transparent transparent;
    }

    .tooltipContainer:hover .tooltipText {
      visibility: visible;
      opacity: 1;
    }
  }
}

.dropdownMenu {
  display: flex !important;
}

.svgLink {
  svg {
    fill: #fff;
  }
}

// pagination

.paginationWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;

  .pageNumber {
    padding: 5px 15px;
  }

  .activePageNumber {
    border-radius: 2px;
    background-color: #f95d5f;
    padding: 5px 15px;
    color: white;
  }
}
