.step3Wrapper {
  border-radius: 8px;
  background-color: #01989f;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0;
  }

  p {
    font-size: 14px;
    font-style: normal;
    line-height: 1.71;
    .email {
      font-weight: 600;
      color: #99e67b;
    }
  }

  .BackButton, .resendButton {
    background-color: transparent;
    border-radius: 4px;
    border: solid 2px #fff;
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    text-transform: uppercase;
    
  } 

  .paymentWrapper {
    margin-top: 20px;
    width: 90%;
  }
}
