.physicalTransformationPage {
  color: #191919;

  .heading {
    font-size: 24px;
    font-weight: 600;
  }

  .bodyPartsWrapper {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;
    margin-top: 20px;
    min-height: 100px;
    .bodyPartsList {
      display: flex;
      align-items: start;
      flex-wrap: nowrap;
      overflow-y: hidden;
      gap: 18px;
      -webkit-overflow-scrolling: touch;
      padding-left: 7px;

      .option {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          border-radius: 50%;
          cursor: pointer;
        }
        h4 {
          font-weight: 500;
          line-height: 1.78;
          text-transform: uppercase;
          text-align: center;
          color: #191919;
          opacity: 0.4;
          font-size: 10px;
          padding-top: 8px;
        }
      }

      .selectedOption {
        img {
          border: 4px solid var(--red-bg);
        }

        h4 {
          color: var(--red-bg);
          opacity: 1;
          font-weight: bold;
        }
      }
    }

    .bodyPartsList::-webkit-scrollbar {
      width: 0em;
      height: 0em;
    }

    .bodyPartsList::-webkit-scrollbar-track {
      background: transparent;
    }

    .bodyPartsList::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }

  .backArrowWhiteActive,
  .nextArrowWhiteActive {
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    top: 40%;
    cursor: pointer;
  }

  .disabled {
    background-color: rgba(0, 60, 63, 0.12) !important;
  }

  .backArrowWhiteActive {
    left: -15px;
    background-color: var(--red-bg);
  }

  .nextArrowWhiteActive {
    right: -13px;
    background-color: var(--red-bg);
  }

  .filters {
    display: flex;
    width: 100%;
    gap: 10px;
    padding: 15px 20px;
    padding-top: 10px;
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;
    margin-top: 20px;

    .filterItem {
      width: 50%;
      .resourcesTitle {
        font-size: 12px;
        font-weight: 600;
        padding-top: 15px;
        padding-bottom: 10px;
        color: #191919;
      }
    }

    .filterItemSingle {
      width: 100%;
    }
  }

  .resultsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 30px;

    .leftSide {
      .resultsHeading {
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.56;
      }
      .searchDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        .profType {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.4px;
          color: #01989f;
          text-transform: uppercase;
        }

        .dot {
          width: 3px;
          height: 3px;
          background-color: #000000;
          margin-inline: 10px;
          border-radius: 50%;
        }

        .locationName {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          color: #191919;
        }
      }
    }
  }

  .physiciansResults {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;

    .professionalWrapper {
      position: relative;
      display: flex;
      gap: 5px;
      background-color: white;
      border-radius: 10px;
      width: 48%;
      height: 200px;
      cursor: pointer;
      .eyeWrapper {
        display: none;
        visibility: hidden;
      }
      .leftSide,
      .rightSide {
        width: 50%;
        display: flex;
        justify-content: center;
        // align-items: center;
        img {
          width: 100%;
          border-radius: 10px;
          max-height: 100%;
        }
      }

      .content {
        position: absolute;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px;
        width: 100%;
        bottom: 0;
        background-color: rgba(34, 32, 32, 0.74);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        .contentLeft {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .userInfo {
            margin-right: 40px;

            h6 {
              padding-bottom: 7px;
              color: white;
              font-size: 12px;
              line-height: 1.67;
              letter-spacing: 0.4px;
              font-weight: 600;
            }

            p {
              color: white;
              font-weight: 300;
              font-size: 12px;
            }
          }
        }

        .contentRight {
          display: flex;
          align-items: center;

          .satisfaitBtn {
            background-color: var(--accecent-color);
            &:hover {
              background-color: var(--accecent-colorHover);
            }
          }

          .pasSatisfaitBtn {
            background-color: var(--customButton-bg);
            &:hover {
              background-color: var(--customButton-bgHover);
            }
          }

          .satisfaitBtn,
          .pasSatisfaitBtn {
            color: white;
            padding: 10px 15px;
            border-radius: 5px;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: 0.4px;
          }
        }
      }
    }

    .professionalWrapperWithBluredImages {
      .leftSide,
      .rightSide {
        position: relative;

        img {
          filter: blur(5px);
          -webkit-filter: blur(5px);
        }

        .eyeWrapper {
          position: absolute;
          display: block !important;
          visibility:visible;
          top: 50%;
          left: 50%;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

          img {
            height: 40px;
            width: auto;
            filter: blur(0px);
            -webkit-filter: blur(0px);
            transform: scale(1);
            transition: 0.3s ease-in-out;
          }

          &:hover {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;

    .pageNumber {
      padding: 5px 15px;
    }

    .activePageNumber {
      border-radius: 2px;
      background-color: #f95d5f;
      padding: 5px 15px;
      color: white;
    }
  }
}
.viewSensitiveImageModal {
  .buttonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;

    .buttonOk,
    .buttonClose {
      padding: 6px 10px;
      border-radius: 8px;
      background-color: var(--accecent-color);
      color: white;
      margin-top: 10px;
      max-width: 180px;
      cursor: pointer;
      &:hover {
        background-color: var(--accecent-colorHover);
      }
    }

    .buttonClose {
      background-color: var(--customButton-bg);
      &:hover {
        background-color: var(--customButton-bgHover);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .physicalTransformationPage {
    .backArrowWhiteActive,
    .nextArrowWhiteActive {
      display: none;
    }

    .filters {
      flex-direction: column;

      .filterItem {
        width: 100%;
      }
    }

    .physiciansResults {
      .professionalWrapper {
        width: 100%;
        height: 250px;
      }
    }
  }
}
