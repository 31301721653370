.body {
  text-align: center;
  h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.11;
    color: #191919;
    margin: 0;
    cursor: pointer;
  }

  .modifyButton {
    border: 2px solid black;
    border-radius: 8px;
    margin: 16px;

    padding: 10px;
  }

  .annuler {
    color: #01989f;
    text-align: center;

    margin-top: 40px;
    margin-bottom: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    p {
      border-bottom: 2px solid #01989f;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
