.StoryModalBody {
  // background-color: red;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    // padding-inline: 20px;

    .leftSide {
      display: flex;
      align-items: center;
      h5 {
        font-size: 18px;
        font-weight: 600;
        color: #191919;
        margin: 0;
        padding-right: 8px;
      }

      svg {
        margin-bottom: 5px;
        g {
          fill: #000000;
        }
      }
    }

    button {
      .close {
        font-size: 30px !important;
      }
    }
  }

  .content {
    // padding-inline: 20px;
    p {
      // opacity: 0.72;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.67;

      strong {
        color: black;
      }
    }

    .title {
      display: flex;
      align-items: baseline;
      padding-top: 30px;
      // padding-bottom: 5px;

      h5 {
        font-size: 12px;
        font-weight: 600;
        font-style: italic;
        padding-right: 15px;
        line-height: 1;
        color: var(--accecent-color);
        text-transform: uppercase;
        white-space: nowrap;
      }

      span {
        font-weight: 300;
        text-transform: lowercase;
        font-style: normal;
      }

      .line {
        width: 100%;
        // padding-left: 20px;
        height: 1px;
        background-color: var(--accecent-color);
      }
    }

    .SearchProfessionals {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      label {
        font-size: 12px;
        font-weight: 600;
        color: #191919;
      }

      .inputWrapper {
        border-radius: 4px;
        border: solid 1px #191919;
        margin: 10px 0;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          outline: none;
          width: 90%;
          background-color: transparent;
        }

        input[type="file"] {
          cursor: pointer;
        }

        input[type="file"]::-webkit-file-upload-button {
          display: none;
        }

        .fontawesome {
          padding-right: 10px;
        }
      }
    }

    .priceSatisfyGrp {
      display: flex;
      align-items: center;
      column-gap: 20px;

      .priceWrapper {
        display: flex;
        align-items: end;

        .priceSign {
          color: var(--red-bg);
          padding-bottom: 5px;
          padding-left: 5px;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .resourcesTitle,
    .resourcesTitle1 {
      font-size: 12px;
      font-weight: 600;
      padding-top: 15px;
      padding-bottom: 8px;
      color: #191919;
    }

    .resourcesTitle1 {
      padding: 0 !important;
      padding-bottom: 5px !important;
    }

    .infoAboutProfessional {
      color: #00565b;
      line-height: 22px;
      padding-top: 5px;
      font-size: 12px;

      .heilightedProfessionalName {
        background-color: yellow;
      }
    }

    .otherQuotes {
      text-align: end;
      cursor: pointer;
      font-size: 14px;
      color: var(--accecent-color);
      padding-right: 5px;
    }

    // Resources radio buttons

    .resourceContainer {
      margin: 5px 0;
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;

      button {
        border: solid 1.5px #d5d5d5;
        padding: 5px;
        padding-inline: 10px;
        border-radius: 16px;
        font-size: 12px;
        color: #d5d5d5;
      }
      // button:hover {
      //   background-color: var(--bg-color);
      //   color: #fff;
      // }

      .activeResource {
        background-color: var(--bg-color);
        color: #fff;
      }
    }

    .physicalTransformation {
      background-color: rgba(0, 0, 0, 0.07);
      margin-inline: -15px;
      padding: 15px;
      padding-top: 0px;
      // margin-bottom: 30px;

      .traitement {
        padding-top: 5px;
      }
    }

    .inputsWrapper {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      div {
        width: 100%;
      }
    }

    // Radio buttons

    .radioButtonsWrapper {
      display: flex;

      input[type="radio"] {
        display: none;
      }

      label {
        cursor: pointer;
        position: relative;
        font-size: 13px;
        color: #191919;
        padding-left: 30px;
        padding-right: 10px;
      }

      label::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 2px solid #484848;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: border-color 400ms ease;
      }

      label::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: var(--accecent-color);
        border: 2px solid var(--accecent-color);
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%) scale(0);
        transition: transform 400ms ease;
      }

      input[type="radio"]:checked + label {
        color: var(--accecent-color);
      }

      input[type="radio"]:checked + label::before {
        border-color: var(--accecent-color);
      }

      input[type="radio"]:checked + label::after {
        transform: translateY(-50%) scale(0.55);
      }
    }

    // Checkboxes

    .transformCheckbox,
    .isAnonymCheckbox {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      margin-inline: -15px;
      margin-top: 10px;

      svg {
        height: 20px;
        width: 20px;
      }

      p {
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .transformCheckbox {
      background-color: rgba(0, 0, 0, 0.07);
      padding-bottom: 10px;
    }

    .transformChkbox {
      background-color: rgba(0, 0, 0, 0.07);
      padding: 15px 15px;
      margin-inline: -15px;
      margin-top: 10px;
    }

    // .checkBoxWrapper {
    //   // margin-bottom: 15px;
    //   padding: 10px 0;
    // }

    // .checkBoxWrapper,
    // .transformChkbox {
    //   display: block;
    //   margin-top: 10px;
    //   input {
    //     padding: 0;
    //     height: initial;
    //     width: initial;
    //     margin-bottom: 0;
    //     display: none;
    //     cursor: pointer;
    //   }

    //   label {
    //     align-items: center;
    //     position: relative;
    //     cursor: pointer;
    //     font-size: 12px;
    //     color: #191919;

    //     span {
    //       padding-left: 28px;
    //     }
    //   }

    //   label:before {
    //     content: "";
    //     -webkit-appearance: none;
    //     background-color: transparent;
    //     border: 1px solid var(--accecent-color);
    //     border-radius: 3px;
    //     padding: 8px;
    //     display: inline-block;
    //     position: relative;
    //     vertical-align: middle;
    //     cursor: pointer;
    //     margin-right: 10px;
    //   }

    //   input:checked + label:after {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     top: 3px;
    //     left: 6px;
    //     width: 6px;
    //     height: 10px;
    //     border: solid var(--accecent-color);
    //     border-width: 0 1px 1px 0;
    //     transform: rotate(45deg);
    //   }
    // }

    // Submit button

    .SubmitStoryButtonWrapper {
      margin-top: 20px;
      font-size: 12px !important;
      button {
        background-color: var(--customButton-bg);
        text-align: center;
        padding: 12px 15px;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 100%;
        color: white;
        text-transform: uppercase;
        font-weight: 500;
      }
      // width: 40%;
    }

    .loaderWrapper {
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 14px 15px;
      padding-bottom: 13px;
      background-color: var(--customButton-bg);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.PhysicalInput {
  margin-top: 12px;
}
.PhysicalInput,
.textArea {
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
  }

  .label {
    width: fit-content;
    cursor: pointer;
  }

  .physicalinputWrapper {
    display: none;
  }

  .inputWrapper {
    position: relative;
    border-radius: 4px;
    border: solid 1px #191919;
    // margin: 6px 0;
    padding: 8px 10px;
    // display: none;
    justify-content: space-between;
    align-items: center;

    input {
      outline: none;
      width: 90%;
      background-color: transparent;
      font-size: 12px;
      font-style: italic;
    }

    textarea {
      outline: none;
      width: 96%;
      background-color: transparent;
      font-size: 13px;
      opacity: 1;
      color: black;
      scrollbar-width: thin;
      // font-style: italic;
    }

    textarea::-webkit-scrollbar-thumb {
      display: none;
    }

    .emogie {
      cursor: pointer;
      position: absolute;
      right: 11px;
      bottom: 15%;
      img {
        height: 15px;
        width: 15px;
      }
    }
    .emogiePicker {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      top: 60px;
      z-index: 1000;
    }

    .charCount {
      position: absolute;
      bottom: 0;
      right: 10px;
      color: #9c9c9c;
      font-size: 10px;
      font-style: italic;
    }

    input[type="file"] {
      cursor: pointer;
    }

    input[type="file"]::-webkit-file-upload-button {
      display: none;
    }

    .fontawesome {
      padding-right: 10px;
    }
  }

  .CustomizeInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: solid 1px #191919;
    margin: 6px 0;
    padding: 8px 10px;
    cursor: pointer;

    p {
      color: #9c9c9c;
      font-size: 12px;
      font-style: italic;
    }
  }
}
.videoWrapper .CustomInput,
.filmWrapper .CustomInput {
  margin-top: 15px;
}
.CustomInput {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
  }

  .inputWrapper {
    position: relative;
    border-radius: 4px;
    border: solid 1px #191919;
    // margin: 6px 0;
    margin: 6px 0 0 0;

    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      outline: none;
      width: 90%;
      background-color: transparent;
      font-size: 12px;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 768px) {
  .content {
    .priceSatisfyGrp {
      flex-direction: column;
      align-items: start !important;

      .priceWrapper {
        width: 100%;
      }
    }
  }
  .emogiePicker {
    right: -30px !important;
    top: 60px;
  }

  .inputsWrapper {
    flex-direction: column;
  }

  .SubmitStoryButtonWrapper {
    width: 100% !important;
  }
}

// .mentions {
//   // margin: 1em 0;
// }

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}
.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-size: 14px !important;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 0px;
  min-height: 143px;
}
.mentions--multiLine .mentions__input {
  border: 1px solid transparent;
  padding: 0px;
  outline: 0;
}

.mentions__suggestions__list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.mentions__suggestions__item--focused {
  background-color: #cee4e5;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  color: var(--accecent-color);
  text-shadow: 1px 1px 1px #f5f7f8, 1px -1px 1px #f5f7f8, -1px 1px 1px #f5f7f8,
    -1px -1px 1px #f5f7f8;
  pointer-events: none;
}
