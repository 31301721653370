.signUpModalBody {
  .signUpModalBodyHeader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #191919;
      margin: 0;
    }

    button {
      .close {
        font-size: 30px !important;
      }
    }
  }

  p {
    opacity: 0.72;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
  }

  .customLineStart,
  .customLineStart1,
  .customLineStart2 {
    position: absolute;
    top: 112px;
    left: 0px;
    background-color: var(--bg-color) !important;
    height: 3px;
    width: 60px;
  }

  .customLineEnd,
  .customLineEnd1,
  .customLineEnd2 {
    position: absolute;
    top: 112px;
    right: 0px;
    // background-color: var(--bg-color) !important;
    height: 3px;
    width: 40px;
  }
}

@media (max-width: 368px) {
  .customLineStart {
    top: 160px !important;
    left: -0px !important;
    width: 40px !important;
  }

  .customLineEnd {
    top: 160px !important;
    right: -0px !important;
  }
}

@media (max-width: 370px) {
  .customLineStart1 {
    top: 136px !important;
    width: 15px !important;
  }
  .customLineStart2 {
    top: 112px !important;
    width: 35px !important;
  }
  .customLineEnd1 {
    top: 136px !important;
    right: 0px !important;
  }
}

@media (min-width: 368px) and (max-width: 592px) {
  .customLineStart {
    top: 136px !important;
    left: -0px !important;
    width: 40px !important;
  }
  .customLineStart1,
  .customLineStart2 {
    width: 35px !important;
  }

  .customLineEnd {
    top: 136px !important;
    right: -0px !important;
  }
}
