.searchContainer {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: solid 1px rgba(25, 25, 25, 0.16);
  background-color: #fff;
  padding: 10px;
  // width: 88%;
  input {
    padding-left: 10px;
    background-color: transparent;
    outline: none;
    border: none;
    font-style: italic;
  }
}

.sharePrivate {
  svg {
    fill: #fff;
    padding-bottom: 3px;
    height: 20px !important;
    width: 20px !important;
    g {
      fill: #fff;
      g {
        fill: #fff;
      }
    }
  }
}

.dropdownMenu {
  display: flex;
  flex-direction: row;
}
.creatPost {
  margin-top: 10px;
  display: flex;
  justify-content: end;

  button {
    border-radius: 8px;
    background-color: var(--accecent-color);
    color: #fff;
    padding: 10px;
    cursor: pointer;
  }

  button:hover {
    background-color: var(--accecent-colorHover);
  }
}

.featuredPostsContainer {
  position: relative;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  margin-top: 50px;
  background-color: #fff;

  .storyHeader {
    padding-bottom: 15px;
    .storyHeaderLeft {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .heading {
        font-size: 24px;
        font-weight: 600;
      }
      .date {
        opacity: 0.32;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #191919;
      }
      p {
        font-size: 14px;
        font-weight: normal;
        opacity: 0.72;
        margin: 0;
        text-align: end;
      }
      .theme {
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.6px;
        text-align: start;
        color: var(--accecent-color);
        text-transform: uppercase;
      }
    }
  }

  .storyBody {
    p {
      font-size: 14px;
      font-weight: normal;
      opacity: 0.72;
      margin: 0;
      white-space: pre-line;
    }

    .showMore {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.4px;
      // margin-top: -10px;
      button {
        color: var(--accecent-color);
      }
    }

    .contentBtn {
      color: var(--accecent-color);
      cursor: pointer;
      padding-left: 5px;
    }

    .details {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icons {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;

        .favStarIcon {
          margin-bottom: 5px;
        }

        .icon {
          cursor: pointer;
        }

        .commentsWrapper {
          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            padding-left: 5px;
          }
        }
      }
    }
  }

  .submitPostButton {
    width: 30%;
    margin-top: 20px;
    color: #fff;
  }

  .reactionsContainer {
    position: absolute;
    right: 30px;
    bottom: -30px;
    display: flex;
    // justify-content: flex-end;
    width: fit-content;
    align-items: center;
    gap: 0.5%;
    border-radius: 24px;
    border: solid 2px rgba(25, 25, 25, 0.12);
    background-color: #f6f6f6;
    padding: 5px;
    // padding-right: 15px;

    .themeFiltratin {
      display: flex;
      justify-content: center;
      align-items: center;

      .selector {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1px;
        width: 100%;
        align-items: center;

        .selectorItem {
          label {
            p {
              padding-left: 5px;
            }
          }
          .selectorItemRadio {
            appearance: none;
            display: none;
          }
          .selectorItemLabel {
            cursor: pointer;
            text-align: center;
            display: flex;
            align-items: center;
            padding: 5px;
            // padding-inline: 10px;
            border-radius: 20px;
            transform: none;
            font-size: 14px;
          }

          .selectorItemLabel:hover {
            background-color: #d5d5d5;
          }
          .selectorItemRadio:checked + .selectorItemLabel {
            background-color: #d5d5d5;
            // color: #fff;
          }
          @media (max-width: 480px) {
            .selector {
              width: 90%;
            }
          }
        }
      }
    }
  }

  .reactionsWrapper {
    position: absolute;
    right: 30px;
    bottom: -30px;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 24px;
    border: solid 1px rgba(25, 25, 25, 0.12);
    background-color: #f6f6f6;
    padding: 3px;

    button {
      padding: 5px;
      border-radius: 16px;
      color: #d5d5d5;
    }
    button:hover {
      background-color: #d5d5d5;
      color: #fff;
    }

    .activeResource {
      background-color: #d5d5d5;
      color: #fff;
    }

    span {
      color: black;
      font-size: 14px;
    }
  }
}

.resourcesTitle {
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #191919;
}

.svgLink {
  svg {
    fill: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .details {
    flex-direction: column;
    align-items: start !important;
    justify-content: start;

    .icons {
      margin-bottom: 10px;
    }
  }

  .storyHeader {
    padding-bottom: 0 !important;
  }

  .storyHeaderLeft {
    flex-direction: column;
    align-items: start !important;

    .date {
      display: flex;
      padding-bottom: 20px;
    }
  }

  .reactionsContainer {
    right: 10px !important;
  }
}
