.contactUsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin: 0 100px;
  // margin-top: 100px;
  border-radius: 12px;
  padding: 20px;

  .contactUsTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  .contactUsForm {
    flex: 1 0 100%;
    max-width: 480px;
    width: 100%;
    padding: 10px 20px;

    .contactDetails {
      display: flex;
      flex-direction: column;
      text-align: start;
      padding-bottom: 20px;
      label {
        margin: 0;
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: 600;
      }

      .contactUsInput,
      .contactUsTextarea,
      .password {
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid #000;
        outline: none;
      }

      .contactUstextarea {
        height: 50px;
      }
    }

    label {
      margin: 0;
      padding-bottom: 10px;
      font-size: 12px;
      font-weight: 600;
    }

    .password {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #000;
      outline: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        outline: none;
        width: 90%;
      }

      button {
        position: relative;

        .slash,
        .passwordSlash {
          position: absolute;
          top: 3px;
          right: 15px;
          padding-top: 20px;
          width: 1px;
          height: 18px;
          transform: rotate(30deg);
          background-color: rgb(58, 57, 57);
        }

        .passwordSlash {
          top: 4px;
        }
      }
    }

    .contactUsFormButton {
      width: 40%;
      display: flex;
      justify-content: center;
      margin-inline: auto;
      text-transform: uppercase;
      color: white;
      font-weight: 400;
      border: none;
      height: 38px;
      border-radius: 4px;
      background-color: #01989f;
      cursor: pointer;
    }

    .contactUsFormButton:hover {
      background-color: #01858c;
    }
  }
}

@media screen and (max-width: 1023px) {
  .contactUsBox {
    margin-inline: auto;
  }
}

@media only screen and (max-width: 768px) {
  .contactUsForm {
    width: 100vw !important;
    padding-inline: 40px !important;
  }
}
