.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 10px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
    margin: 0;
  }

  p {
    padding-right: 50px;
    line-height: 1.71;
    // font-size: 14px;
  }

  button {
    margin-top: -5px;
  }
}

.body {
  .Checkbox {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    margin-inline: -15px;
    margin-top: 10px;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;
    }

    p {
      padding-left: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #191919;
    }

    .boldText {
      font-weight: 600;
    }
  }
}
