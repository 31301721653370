.VerifyEmailConntainer {
  border-radius: 12px;
  background-color: #00565b;
  color: #fff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .heading {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    padding-bottom: 30px;
  }
  .buttonWrapper {
    color: #fff;
  }

  .verify {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    padding: 40px 0;

    .link {
      color: #99e67b;
    }
  }

  .details {
    font-family: Poppins;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    opacity: 0.8;
    padding-bottom: 30px;

    .link {
      color: white;
      text-decoration: underline;
    }
  }

  .seeYou {
    font-size: 24px;
    font-weight: bold;
  }
}
