.plansContainer {
  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    .button {
      background-color: var(--accecent-color);
      color: white;
      padding: 10px 20px;
      border-radius: 12px;
      cursor: pointer;
    }
  }

  .plansHeading {
    font-size: 18px;
    color: var(--bg-color);
  }

  hr {
    margin-bottom: 20px;
    background-color: var(--bg-color);
  }

  .cardsContainer {
    .card {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      padding: 20px;
      width: 100%;
      border-radius: 12px;
      margin: 10px 0;
      background-color: var(--bg-color);
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .planName {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

.featuresList {
  border: 1px solid #ccc;
  border-radius: 8px;
  .feature {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
    }

    p {
      padding-left: 10px;
    }
  }
}
