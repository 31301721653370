.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  // justify-content: space-around;
}

.card {
  width: 30%;
  height: 130px;
  margin-top: 10px;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: solid 4px #fff;

  p {
    margin: 0;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  .svgFill {
    svg {
      fill: var(--bg-color) !important;
    }
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: var(--accecent-color);
  border-radius: 4px;
  padding: 15px 20px;
  margin-top: 10px;
  width: 25%;
  margin-inline: auto;
}

.NextButtonWrapper {
  margin-top: 20px;
  justify-content: center;
  display: flex;
  width: 25%;
  flex-direction: column;
  margin-inline: auto;

  // position: relative;

  .backBtn {
    border: 2px solid var(--accecent-color);
    border-radius: 4px;
    padding: 8px 20px;
    margin-top: 10px;
    color: var(--accecent-color);
    // font-size: 14px;
    text-transform: uppercase;
    &:hover {
      color: #fff;
      background-color: var(--accecent-color);
    }
  }
  .NextButton {
    color: #fff;
    border-radius: 4px;
    background-color: var(--accecent-color);
    padding: 10px 20px;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .NextButtonWrapper {
    width: 100%;

    .backBtn {
      top: 0;
      margin: 0 !important;
      // padding: 20px;
    }

    .NextButton {
      width: 100%;
      // padding: 20px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  .cardsWrapper {
    flex-direction: column;
  }

  .card {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
