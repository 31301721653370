.topContent {
  text-align: center;

  .heading {
    line-height: 1;
    padding-top: 15px;
    font-size: 14px;
    color: var(--accecent-color);
    font-weight: 600;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  .subHeading {
    font-size: 20px;
    line-height: 1.78;
    font-weight: 500;
  }

  .paragraph {
    font-size: 12px;
    line-height: 1.33;
  }
}
.resourcedetails4ListWrapper {
  .resourceList {
    border-radius: 8px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;

    .resourcelistHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .resourcelistHeading {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: var(--accecent-color);
      }

      .arrowDown {
        transform: rotate(-90deg);
      }

      .arrowUp {
        transform: rotate(90deg);
      }
    }

    .descriptionWrapper {
      .description,
      .innerList {
        font-size: 12px;
        line-height: 1.2;

        padding-top: 10px;
        font-weight: 400;

        span {
          font-weight: 600;
        }
      }

      .description {
        padding-left: 10px;
      }

      .innerList {
        padding-left: 30px;
      }
    }
  }
}
