.messagesWrapper {
  width: 100%;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 85vh;

  .conversations {
    width: 100%;

    // margin-right: 10px;
    .header {
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }

        .rightIcons {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          cursor: pointer;
        }

        .crossIcon {
          display: block;
          padding-top: 3px;
          padding-left: 10px;
        }
      }

      .inputWrapper {
        margin-top: 20px;
        border-radius: 4px;
        border: solid 1px rgba(25, 25, 25, 0.2);
        background-color: #fff;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-style: italic;
        color: #191919;

        input {
          width: 100%;
        }
      }
    }

    .body {
      padding-top: 30px;
      overflow: auto;

      .convoWrapper {
        overflow: auto;
        height: 57vh;

        .messages {
          display: flex;

          justify-content: space-between;
          padding: 15px 5px;
          border-bottom: solid 1px rgba(25, 25, 25, 0.2);

          cursor: pointer;

          .messageLeft {
            display: flex;
            align-items: center;

            .userPic {
              height: 35px;
              width: 35px;
              border-radius: 50%;
            }

            .greenDot {
              margin-left: -0.8rem;
              margin-bottom: -1.5rem;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #99e67b;
            }

            .whiteDot {
              margin-left: -0.8rem;
              margin-bottom: -1.5rem;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: #f5f7f8;
              border: 1px solid #191919;
            }

            .userName {
              padding-left: 10px;

              h5 {
                font-size: 16px;
              }

              p {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.4px;
              }
            }
          }

          .messageRight {
            display: flex;
            align-items: center;

            p {
              border-radius: 50%;
              // padding: 3px 10px;
              height: 25px;
              width: 25px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #f95d5f;
              color: #fff;
            }
          }

          &:hover {
            background-color: #f5f7f8;
          }
        }
      }
    }
  }

  .activeConversation {
    background-color: #f0f0f0;
  }

  .chat {
    width: 100%;
    border-radius: 12px;
    background-color: #f5f7f8;
    padding: 20px 10px;
    position: relative;

    .chatHeader {
      display: flex;

      align-items: center;
      margin-bottom: 20px;

      .back {
        cursor: pointer;
      }

      .userPic {
        height: 35px;
        width: 35px;
        border-radius: 50%;
      }

      .greenDot {
        position: absolute;
        margin-left: 4rem;
        margin-bottom: -1.5rem;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #99e67b;
      }

      .whiteDot {
        position: absolute;
        margin-left: 4rem;
        margin-bottom: -1.5rem;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #f5f7f8;
        border: 1px solid #191919;
      }

      .userName {
        padding-left: 10px;

        h5 {
          font-size: 16px;
        }

        p {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.4px;
        }
      }
    }

    .date {
      font-size: 12px;
      color: #191919;
      text-align: center;
    }

    .chitchat {
      overflow: auto;
      height: 55vh;
      margin-bottom: 35px;

      .messageBox {

        .receiverMsg,
        .senderMsg {
          border-radius: 20px;
          border: solid 1px rgba(25, 25, 25, 0.16);
          max-width: 50%;
          width: fit-content;
          padding: 5px 20px;
          padding-bottom: 5px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          a {
            font-size: 14px;
            color: var(--accecent-color);
          }

          .msgTime {
            margin-left: 1rem;
            text-align: right;
            font-size: 0.65em;
          }
        }

        .receiverMsg {
          background-color: #f5f7f8;
        }

        .senderMsg {
          margin-left: auto;
          margin-right: 5px;
          background-color: rgba(25, 25, 25, 0.16);
        }

        .receiverStory,
        .senderStory {
          border-radius: 20px;
          border: solid 1px rgba(25, 25, 25, 0.16);
          width: 50%;
          padding: 5px 0px;
          padding-bottom: 5px;
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .storyHeader {
            display: flex;
            align-items: center;
            padding: 0 5px;

            .dpWrapper {
              height: 30px;
              width: 30px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .contentWrapper {
              padding-left: 5px;

              .name {
                font-size: 12px;
              }

              .username {
                font-size: 10px;
              }
            }
          }

          .storyBody {
            margin-top: 10px;
            padding: 10px;
            background-color: white;

            .bodyTop {
              display: flex;
              align-items: center;

              .theme {
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                letter-spacing: 0.6px;
                color: var(--accecent-color);
                text-align: start;
                text-transform: uppercase;
              }
            }

            .bodyBottom {
              .title {
                font-weight: 600;
                font-size: 14px;
              }

              .content {
                font-size: 12px;
                font-weight: normal;
                opacity: 0.82;
                margin: 0;
                padding-top: 5px;
                overflow-wrap: break-word;
              }
            }
          }

          .msgTimeForStory {
            padding-top: 3px;
            padding-right: 20px;
            text-align: right;
            font-size: 0.65em;
          }
        }

        .receiverStory {
          background-color: #f5f7f8;
        }

        .senderStory {
          margin-left: auto;
          margin-right: 5px;
          background-color: rgba(25, 25, 25, 0.16);
        }

        &::-webkit-scrollbar {
          width: 0;
        }
      }
    }

    form {
      position: absolute;
      bottom: 15px;
      width: 95.9%;

      input {
        border-radius: 22px;
        border: solid 1px rgba(25, 25, 25, 0.16);
        background-color: #fff;
        padding: 7px 20px;
        font-size: 14px;
        color: #191919;
        width: 100%;
      }
    }

    .messageInputDisabled {
      input {
        cursor: not-allowed !important;
        background-color: lightgrey !important;
      }
    }

    .blockedText {
      position: absolute;
      bottom: 10px;
      width: 100%;
      text-align: center;
    }


    .emogie {
      cursor: pointer;
      position: absolute;
      right: 25px;
      bottom: 22px;

      img {
        height: 18px;
        width: 18px;
      }

      .sendMessageBtn {
        padding-left: 10px;
      }
    }

    .emogiePicker {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: -20px;
      // left: 10px;
      bottom: 55px;
      z-index: 1000;
    }
  }


}

@media only screen and (max-width: 768px) {
  .messagesWrapper {
    height: 75vh;
  }

  .conversations {
    width: 100% !important;

    .header {
      .top {
        .crossIcon {
          display: block !important;
        }
      }
    }
  }

  .body {
    .convoWrapper {
      height: 48vh !important;
    }
  }

  .chat {
    width: 100%;

    .chatHeader {
      display: flex !important;
    }

    .chitchat {
      height: 44vh !important;
    }
  }

  .receiverMsg,
  .senderMsg {
    max-width: 70% !important;
  }

  .receiverStory,
  .senderStory {
    width: 80% !important;
  }
}