.pricingPlansContainer {
  background-color: var(--accecent-color);
  border-radius: 10px;
  color: white;
  padding: 20px 0;
  margin: -10px;
  .planCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
    gap: 10px;

    .cardWrapp {
      width: 45%;

      .popular {
        background-color: var(--logo-color);
        text-align: center;
        width: fit-content;
        max-width: 190px;
        margin-inline: auto;
        color: var(--bg-color);
        padding: 5px 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        font-size: 12px;
        font-weight: 600;
      }
      .planCard {
        background-color: var(--bg-color);
        padding: 10px;
        border-radius: 10px;

        .planName {
          text-align: center;
          text-transform: uppercase;
          color: var(--logo-color);
        }

        .line {
          height: 2px;
          width: 70%;
          background-color: #19868bb4;
          margin: 15px auto;
        }

        .priceWrapp {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 80px;
          .heading {
            text-transform: uppercase;
            color: var(--red-bg);
            text-align: center;
            font-size: 22px;
            span {
              text-transform: lowercase;
              font-size: 12px;
              font-weight: 300;
            }
          }

          .detail {
            color: var(--red-bg);
            text-align: center;
            font-size: 12px;
            padding-top: 5px;
            font-weight: 300;
          }
        }

        @media only screen and (max-width: 750px) {
          .priceWrapp {
            height: fit-content !important;
          }
        }

        .description {
          text-align: center;
          font-size: 12px;
          padding: 5px 0;
          font-weight: 300;
        }

        ul {
          padding: 0;
          margin-right: -5px !important;
          li {
            list-style: none;
            font-size: 12px;
            font-weight: 300;

            span {
              padding-right: 8px;
            }
          }

          .unavailableFeature {
            opacity: 0.7;
          }
        }

        .button {
          padding: 10px 30px;
          text-align: center;
          border-radius: 10px;
          width: 80%;
          font-weight: 600;
          margin-inline: auto;
          background-color: var(--red-bg);
          cursor: pointer;
        }
      }
    }
  }
}

// modal styles
.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.professionalTypesBody {
  .cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    // justify-content: space-around;
  }

  .activeCard {
    border: 4px solid var(--bg-color) !important;
    p {
      color: var(--bg-color) !important;
      opacity: 1 !important;
      font-weight: bold !important;
    }
  }
  .card {
    width: 30%;
    height: 150px;
    margin-top: 10px;
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border: solid 4px #e4e4e4;

    p {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #191919;
      opacity: 0.4;
    }
  }

  .professionalsDescription {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #191919;
    opacity: 0.4;
    text-align: center;
  }
}

.professionalJobBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  .inputDiv {
    width: 80%;
    padding: 12px 20px;
    border-radius: 4px;
    border: solid 1px rgba(25, 25, 25, 0.16);
    background-color: #fff;
  }
  .searchButtonMobileView {
    padding: 14px;
    border-radius: 8px;
    background-color: #01989f;
    cursor: pointer;
    width: fit-content;
  }
}

.signUpForm {
  margin-top: 15px;
  a {
    text-decoration: underline;
  }
  .inputGroup {
    display: flex;
    justify-content: space-between;

    .inputContainer {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 45%;
      label {
        font-size: 12px;
        font-weight: 600;
        color: #191919;
      }

      input {
        border-radius: 4px;
        border: solid 1px #191919;
        margin-top: 0px;
        padding: 5px 10px;
        outline: none;
      }

      /* Styles to hide the calendar icon */
      input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url(../../assets/images/calenderIcon.svg) no-repeat;
        border-width: thin;
        padding-right: 5px;
      }

      input[type="date"]::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
      }

      input[type="date"],
      input[type="time"] {
        outline: 0;
        // color: gray;
        position: relative;
        width: 100%;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="time"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }

      .inputEyeWrapper {
        display: flex;
        position: relative;
        button,
        img,
        .calenderIcon {
          position: absolute;
          right: 6px;
          top: 4px;
        }
        .calenderIcon {
          right: 13px;
          top: 7px;
          display: none;
          cursor: pointer;
        }

        .eyeClose {
          padding-top: 2px;
          padding-right: 1px;
        }

        button {
          .slash {
            position: absolute;
            top: 6px;
            right: 16px;
            padding-top: 20px;
            width: 1px;
            height: 20px;
            transform: rotate(30deg);
            background-color: rgb(58, 57, 57);
          }
        }

        // img {
        //   display: none;
        // }
      }
    }
  }

  .signupButton {
    color: var(--accecent-color);
    text-align: center;
    margin-top: 20px;
    width: 70%;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    p {
      border-bottom: 2px solid var(--accecent-color);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .conditionsText {
    margin-top: 10px;
  }
}

.errorMsg {
  color: red;
  font-size: 12px !important;
}

.buttonWrapper {
  justify-content: center;
  display: flex;
  margin-inline: auto;
  padding-top: 15px;

  .buttonSuccess {
    color: #fff;
    border-radius: 4px;
    background-color: var(--accecent-color);
    font-size: 18px;
    padding: 10px 20px;
    width: 50%;
  }

  .buttonRed {
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    background-color: var(--red-bg);
    width: 50%;
  }

  .buttonOutline {
    color: var(--accecent-color);
    font-weight: bold;
    border-radius: 10px;
    padding: 10px 20px;
    border: 3px solid var(--accecent-color);
    width: 50%;
  }
}

.return {
  color: var(--accecent-color);
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-position: under;
  text-underline-offset: 2px;
  cursor: pointer;
}

.professionalIdentityBody {
  .professionalDetail {
    font-size: 14px;
  }

  .professionalIdentityheading {
    text-align: center;
    font-weight: bold;
    padding: 20px 0;
  }

  .nameCard {
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    padding: 10px;
    background-color: #fff;

    h6 {
      font-weight: bold;
    }
  }
}

.professionalIdentityCancleBody {
  p {
    font-size: 14px;
    color: var(--bg-color);
    font-weight: bold;
  }
}

.professionalOnSuccessBody {
  border-radius: 8px;
  background-color: #01989f;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 0;
  }

  p {
    font-size: 14px;
    font-style: normal;
    line-height: 1.71;
    .email {
      font-weight: 600;
      color: #99e67b;
    }
  }

  .BackButton,
  .resendButton {
    background-color: transparent;
    border-radius: 4px;
    border: solid 2px #fff;
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    text-transform: uppercase;
  }

  .paymentWrapper {
    margin-top: 20px;
    width: 90%;
  }
}

@media only screen and (max-width: 750px) {
  .pricingPlansContainer {
    padding: 20px;
    .planCardsContainer {
      flex-direction: column;

      .cardWrapp {
        width: 100%;
      }
    }
  }

  .cardsWrapper {
    flex-direction: column;
    row-gap: 0px !important;
    .card {
      width: 100%;
      height: 80px;

      p {
        text-align: start;
        margin: 0;
        padding: 0;
      }
    }
  }

  .buttonWrapper {
    width: 95%;
    margin-inline: auto;
    .buttonSuccess,
    .buttonRed {
      width: 100%;
    }
  }

  .inputGroup {
    flex-direction: column;

    .inputContainer {
      width: 100% !important;

      input {
        width: 100%;

        // margin-top: 5px !important;
      }

      input[type="date"],
      input[type="time"] {
        outline: 0;
        position: relative;
        width: 100%;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
        height: 35px !important;
      }

      input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        // left: 0;
        position: absolute;
        right: 6px;
        top: 4px;
        width: 20px;
        z-index: 1000;
      }
      input[type="date"] {
        display: inline-flex;
      }

      .inputEyeWrapper {
        img {
          display: block !important;
        }
      }
    }
  }
}
