.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 31px;
}
.activeCard {
  border: 4px solid var(--bg-color) !important;
  p {
    color: var(--bg-color) !important;
    opacity: 1 !important;
    font-weight: bold !important;
  }
}
.card {
  width: 30%;
  height: 150px;
  margin-top: 10px;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border: solid 4px #fff;

  p {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #191919;
    opacity: 0.4;
  }
  .svgFill {
    svg {
      fill: #00565b !important;
    }
  }
}

.NextButtonWrapper {
  color: #fff !important;
  margin-top: 20px;
  justify-content: center;
  display: flex;
  margin-inline: auto;
  .NextButton {
    border-radius: 4px;
    background-color: #01989f;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .NextButtonWrapper {
    width: 100%;
    .NextButton {
      width: 100%;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .cardsWrapper {
    flex-direction: column;
    row-gap: 0px !important;
  }

  .card {
    width: 100%;
    height: 80px;
    flex-direction: row-reverse;
    justify-content: space-between;

    p {
      text-align: start;
      margin: 0;
      padding: 0;
    }

    img {
      margin-right: 10px;
    }
  }
}
