.professionalsContainer {
  color: #191919;
  position: relative;
  .heading {
    font-size: 24px;
    font-weight: 600;
    span {
      font-size: 16px;
    }
  }

  .headingMobileView {
    display: none;
    font-size: 16px;
    padding-top: 10px;
  }

  .searchWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .searchBox {
      display: flex;
      border-radius: 4px;
      border: solid 1px rgba(25, 25, 25, 0.16);
      background-color: #fff;
      width: 89%;

      .inputDivWrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .inputDiv {
          width: 100%;

          .customeLocationWrapper {
            display: flex;
            gap: 5px;
            align-items: center;
            // width: inherit;
            justify-content: flex-end;
            cursor: pointer;

            .slideContent {
              position: absolute;
              left: 1px;
              right: 1px;
              top: 1px;
              bottom: 1px;
              padding-inline: 20px;
              background-color: #fff;
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: space-between;

              .left {
                display: flex;
                gap: 10px;
                align-items: center;
              }
            }
          }
        }
      }

      .borderRed,
      .borderRedDesktop {
        border: 1px solid var(--red-bg) !important;
      }

      .borderRedMobileView {
        display: none;
      }

      .hideDesktopError {
        display: none;
      }

      .inputDiv {
        display: flex;
        align-items: center;

        padding-right: 15px;
        padding-left: 20px;

        input {
          width: 100%;
          margin-left: 10px;
        }
      }

      .inputDiv1 {
        border-right: 1px solid rgba(25, 25, 25, 0.16);
      }

      .searchButtonMobileView {
        display: none;
        padding: 15px;
        border-radius: 8px;
        background-color: #01989f;
        cursor: pointer;
        width: fit-content;
        margin-left: 15px;
      }
    }

    .searchButton {
      padding: 10px;
      border-radius: 8px;
      background-color: #01989f;
      cursor: pointer;
    }
  }

  .desktopFormErrorsWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    .hideMobileError {
      display: block;
    }
    .hideMobileError2 {
      display: block;
      position: absolute;
      top: 0;
      right: 35%;
    }
  }

  /* Slide-in animation */
  .slideIn {
    animation: slideIn 0.3s forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* Slide-out animation */
  .slideOut {
    animation: slideOut 0.3s forwards;
  }

  @keyframes slideOut {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  .resultsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 30px;

    .leftSide {
      .resultsHeading {
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.56;
      }
      .searchDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        .profType {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.4px;
          color: #01989f;
          text-transform: uppercase;
        }

        .dot {
          width: 3px;
          height: 3px;
          background-color: #000000;
          margin-inline: 10px;
          border-radius: 50%;
        }

        .locationName {
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          color: #191919;
        }
      }
    }

    .rightSide {
      .findeProfButton {
        border-radius: 4px;
        background-color: var(--bg-color);
        color: white;
        padding: 10px 25px;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
      }
    }
  }

  .professionalWrapper {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;
    margin-bottom: 20px;

    .top {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;

      .profPic {
        // margin-right: 20px;
        border-radius: 20px;
        height: 130px;
        width: 130px;
      }

      .rightSide {
        width: 100%;
        .nameReviews,
        .address {
          display: flex;
          align-items: center;
        }

        .nameReviews {
          align-items: start;
          .professionalName {
            cursor: pointer;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            color: #000000;
            color: var(--accecent-color);
          }

          .firmName {
            color: var(--accecent-color);
            text-transform: capitalize;
          }

          .profType {
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            padding: 15px 0;
          }

          .rating {
            padding-inline: 10px;

            img {
              height: auto;
              width: 15px;
            }
          }

          .reviewCount {
            font-size: 12px;
            line-height: 1;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .address {
          display: flex;
          justify-content: space-between;
          .locationWrapper {
            // width: 70%;
            // margin-right: 5px;
            .location,
            .website {
              display: flex;
              align-items: center;
              p,
              a {
                font-size: 12px;
                line-height: 1;
                padding-left: 10px;
                color: black;
              }

              a:hover {
                text-decoration: underline;
              }
            }

            .website {
              padding-top: 10px;
            }
          }
          .phone {
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: solid 2px #f95d5f;
            padding: 10px 15px;

            p {
              font-size: 12px;
              line-height: 1;
              color: #f95d5f;
              padding-left: 5px;
            }
          }
        }
      }
    }

    .addressMobileView {
      display: none;

      .locationWrapper {
        // width: 70%;
        // margin-right: 5px;
        .location,
        .website {
          display: flex;
          align-items: center;
          a,
          p {
            font-size: 12px;
            line-height: 1;
            padding-left: 10px;
            color: black;
          }

          a:hover {
            text-decoration: underline;
          }
        }

        .location {
          margin-top: 20px;
        }

        .website {
          padding-top: 10px;
        }
      }
      .phone {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: solid 2px #f95d5f;
        padding: 10px 15px;
        width: fit-content;
        margin-top: 20px;

        p {
          font-size: 12px;
          line-height: 1;
          color: #f95d5f;
          padding-left: 5px;
        }
      }

      .availibilityWrapper {
        border-top: 3px solid #f5f7f8;
        margin-top: 10px;
        margin-inline: -15px;
        .heading {
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          padding: 10px 0;
        }

        .appointmentsWrapp {
          margin-inline: 15px;
          .appointmentDetail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding-bottom: 10px;

            p {
            }

            .dates {
              display: flex;
              align-items: center;
              gap: 20px;

              .gray,
              .red {
                padding: 6px 10px;
                border-radius: 12px;
                background-color: lightgray;
                cursor: pointer;
                color: #383838;
              }

              .red {
                background-color: var(--red-bg);
                color: white;
              }
            }
          }
        }
      }
    }

    .bottom {
      margin-top: 40px;
      .subTypesContainer {
        margin: 5px 0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        gap: 8px;
        align-items: center;

        button {
          background-color: var(--bg-color);
          padding: 8px;
          padding-inline: 10px;
          border-radius: 5px;
          font-size: 12px;
          color: #fff;
        }
        button:hover {
          background-color: var(--accecent-color);
        }

        .activeSubType {
          background-color: var(--accecent-color);
        }
      }
    }
  }

  .paginationWrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 40px;

    .pageNumber {
      padding: 5px 15px;
    }

    .activePageNumber {
      border-radius: 2px;
      background-color: #f95d5f;
      padding: 5px 15px;
      color: white;
    }
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.11;
    color: #191919;
    margin: 0;
  }
}

.modalBody {
  .text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
    color: #000000;
    padding-inline: 30px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .heading {
    span {
      display: none !important;
    }
  }
  .headingMobileView {
    display: block !important;
  }
  .searchWrapper {
    .searchBox {
      flex-direction: column;
      border-radius: 4px;
      border: none !important;
      background-color: transparent !important;
      width: 100% !important;

      .inputDivWrapper {
        margin-top: 10px;
      }

      .borderRedDesktop {
        border: none !important;
      }

      .borderRedMobileView {
        display: block;
        border: 1px solid var(--red-bg) !important;
      }

      .hideDesktopError {
        display: block !important;
      }

      .inputDiv {
        padding: 15px 20px;
        border-radius: 4px;
        border: solid 1px rgba(25, 25, 25, 0.16);
        background-color: #fff;

        &:nth-child(2) {
          width: 80%;
        }

        .customeLocationWrapper {
          display: flex;
          gap: 5px;
          align-items: center;
          // width: inherit;
          justify-content: flex-end;
          cursor: pointer;

          .slideContent {
            left: 1px;
            right: 12% !important;
            top: 1px;
            bottom: 1px;
          }
        }
      }

      // .inputDiv1 {
      //   border-right: 1px solid var(--red-bg) !important;
      // }
      .searchButtonMobileView {
        display: block !important;
      }
    }

    .searchButton {
      display: none !important;
    }
  }

  .desktopFormErrorsWrapper {
    .hideMobileError,
    .hideMobileError2 {
      display: none !important;
    }
  }

  .resultsHeader {
    flex-direction: column-reverse;
    align-items: flex-start !important;

    .rightSide {
      width: 100% !important;
      //   max-width: none!important;
      margin-bottom: 30px !important;
      .findeProfButton {
        padding: 15px 25px !important;
      }
    }
  }

  .professionalWrapper {
    .top {
      .profPic {
        // margin-right: 20px;
        border-radius: 20px;
        height: 100px !important;
        width: 100px !important;
      }

      .rightSide {
        .nameReviews {
          flex-direction: column;
          align-items: flex-start !important;

          .rating {
            padding: 0 !important;
            display: flex;
            align-items: center;
          }

          .reviewCount {
            padding-top: 10px;
            padding-left: 10px;
            padding-bottom: 10px;
          }
        }

        .profType {
          padding: 0 !important;
        }

        .address {
          display: none !important;
        }
      }
    }

    .addressMobileView {
      display: block !important;
    }
  }
}
