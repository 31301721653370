.reviewContainer {
  background-color: rgba(0, 87, 91, 0.98);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1;

  .rating {
    img {
      height: auto;
      width: 15px;
    }
  }

  .reviewsHeader {
    margin-inline: 29%;
    margin-top: 40px;

    .reviewsHeaderTop {
      display: flex;
      justify-content: space-between;

      .reviewsHeaderTopLeft {
        display: flex;
        align-items: center;

        h2 {
          font-size: 18px;
          font-weight: 600;
          line-height: 1;
          padding-right: 10px;
        }

        .totalReviews {
          display: flex;
          align-items: center;

          p {
            font-size: 12px;
            line-height: 1;
            margin-top: 3px;
          }
        }
      }
    }

    .profType {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.4px;
      color: #01989f;
      text-transform: uppercase;
    }

    .totalReviewsMobileView {
      display: none;
    }
  }

  .reviewInputWrapper {
    margin-inline: 29%;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;
    margin-bottom: 30px;
    font-size: 14px;
    font-style: italic;
    line-height: 1;

    input {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .buttonWrapper {
      display: flex;
      justify-content: flex-end;
    }

    .submitButton {
      width: 100%;
      background-color: var(--customButton-bg);
      padding: 12px 20px 14px;
      border-radius: 4px;
      cursor: pointer;
      width: fit-content;

      .loader {
        padding: 0px 33px;
      }
      p {
        color: white;
        font-style: normal;
      }
    }
  }

  .review {
    .nameDate {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.13;
      }
    }
  }

  .reviewsHeader,
  .review {
    margin-inline: 29%;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    background-color: #fff;
    margin-bottom: 10px;

    p {
      font-size: 12px;
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 768px) {
  .reviewContainer {
    .reviewsHeader {
      margin-inline: 5% !important;
      margin-top: 100px !important;

      .reviewsHeaderTop {
        .reviewsHeaderTopLeft {
          .totalReviews {
            display: none;
          }
        }
      }
    }

    .totalReviewsMobileView {
      display: block !important;
    }

    .review,
    .reviewInputWrapper {
      margin-inline: 5%;
    }
  }
}
