.backButton {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  padding-bottom: 20px;

  p {
    text-transform: uppercase;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 600;
  }
}

.bestAppsContainer {
  position: relative;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 40px;
  background-color: #fff;
  cursor: pointer;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: start;

    .content {
      padding-right: 20px;

      .headingWrapp {
        // min-height: 60px;
        .heading {
          font-size: 14px;
          font-weight: 600;
        }

        .subHeading {
          font-size: 12px;
          font-weight: 600;
        }
      }

      p {
        padding-top: 5px;
        font-size: 13px;
      }
    }

    .iconContainer {
      .iconWrapper {
        height: 50px;
        width: 50px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .bottom {
    p {
      font-size: 13px;
      color: black;
      padding-top: 5px;
    }
  }
}

.topIconClass {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  padding-bottom: 30px;
  .iconWrapp {
    height: auto;
    width: auto;

    svg {
      height: 100%;
      width: 100%;

      path {
        stroke: var(--accecent-color);
      }
      g {
        path,
        ellipse,
        polyline,
        circle,
        polygon,
        rect,
        line {
          stroke: var(--accecent-color);
        }
      }
    }
  }

  .iconWrappPng {
    height: 130px;
    // width: 130px;
    // margin-bottom: 40px;
    border-radius: 20px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }
}