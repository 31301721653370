.customBackdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}
.ModalBody {
  overflow: hidden;
  padding: 20px 0;
  padding-right: 20px;
  .ModalBodyHeader {
    padding-inline: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px !important;

    h5 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }

    img {
      height: 25px;
      width: 30px;
    }

    button {
      .close {
        font-size: 30px !important;
        color: #ffff;
      }
    }
  }
}

.themes {
  margin: 20px 0;
  padding-right: 15px;
  overflow-y: auto !important;
  height: 474px;
  color: #fff;
  gap: 10px;

  .selector {
    .selectorItem {
      overflow-y: auto !important;
      border-bottom: 1px solid #b8b8b869;

      .selectorItemLabel {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-align: center;
        background-color: var(--accecent-color);
        color: #fff;
        padding: 15px;
        padding-inline: 10px;
        padding-left: 20px;
        transform: none;
        font-size: 14px;

        .iconWrapper {
          position: relative;

          .dot {
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: var(--red-bg);
            height: 7px;
            width: 7px;
            border-radius: 50%;
          }
        }
        p {
          padding-left: 20px;
          text-align: start;
        }
      }

      @media (max-width: 480px) {
        .selector {
          width: 90%;
        }
      }
    }
  }
}

.activeConversation {
  background-color: var(--bg-color) !important;
}

@media (max-width: 768px) {
  .ModalBody {
    height: 100%;
  }

  .themes {
    height: 85% !important;
    margin-bottom: 20px !important;
  }
  .themesWrapper {
    height: 100% !important;
  }
}
