.bodyHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  padding-bottom: 10px;

  h5 {
    font-size: 18px;
    font-weight: 600;
    color: #191919;
    margin: 0;
  }

  button {
    padding: 0;
    margin: 0;
    .close {
      font-size: 30px !important;
    }
  }
}
.profileVisibilty {
  margin: 10px 0;
  display: flex;
  align-items: center;
  label {
    font-size: 10px;
    color: #191919;
    padding-right: 5px;
  }

  .profileVisibiltyDropdown {
    width: 50%;
  }
}
.content {
  padding: 0 10px;
}

.picBioWrapper {
  display: flex;
  // flex-direction: column;
  .profilepicWrapper {
    padding: 20px 0;
    // display: flex;
    width: 20%;
    // justify-content: center;

    .form-left {
      width: fit-content;
      position: relative;

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-color: #191919;
        transition: 0.3s ease;
        border-radius: 50%;
      }

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
      }
      input {
        display: none;
      }
      label {
        display: block;
        cursor: pointer;
        text-align: center;
        font-weight: 500;
        color: red;
      }
      .image-upload-button {
        height: 95px;
        width: 95px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: var(--bg-color);
        background: linear-gradient(135deg, #fff253, #99e67b 50%, #01989f);
        &:hover {
          // opacity: 0.7;
        }
      }
      img {
        height: 80px;
        width: 80px;
        display: block;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .form-left:hover .overlay {
      opacity: 0.6;
    }
  }

  .bio {
    padding-bottom: 20px;
    width: 100%;
    padding-left: 20px;
    position: relative;
    // .title {
    //   font-size: 12px;
    //   font-weight: 600;
    //   color: #191919;
    //   padding-bottom: 5px;
    // }

    .emogie {
      cursor: pointer;
      position: absolute;
      right: 10px;
      bottom: 32px;
      display: flex;
      img {
        height: 15px;
        width: 15px;
      }
    }
    .emogiePicker {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      top: 60px;
      z-index: 1000;
    }

    .errorMessage {
      position: absolute;
      left: 20px;
      bottom: 10px;
    }
    .details {
      padding: 10px;
      width: 100%;
      outline: none;
      border-radius: 4px;
      border: solid 1px #191919;
      font-size: 12px;
      font-weight: 500;
      line-height: 2;
      color: #191919;
    }

    .details::-webkit-scrollbar {
      width: 0px !important;
      height: 0px !important;
    }
  }
}

.themesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .themeVisibilty {
    width: 50%;
  }
}

.title {
  font-size: 12px;
  font-weight: 600;
  color: #191919;
  padding-bottom: 5px;
  padding-right: 5px;
}

.passWrapper {
  display: flex;
  gap: 20px;
  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    label {
      font-size: 12px;
      font-weight: 600;
      color: #191919;
    }

    input {
      border-radius: 4px;
      border: solid 1px #191919;
      margin: 10px 0;
      padding: 5px 10px;
      outline: none;
    }
  }
}
.submitButton {
  color: #fff !important;
  width: 50%;
  display: flex;
  margin-inline: auto;
  margin-top: 20px;
}

// image upload

@media (max-width: 768px) {
  .picBioWrapper {
    flex-direction: column;
    .bio {
      padding-left: 0px;

      // .emogie {
      //   right: 10px;
      //   bottom: 20%;
      // }
      .emogiePicker {
        right: -30px !important;
        top: 60px;
      }

      .errorMessage {
        left: 0px !important;
      }
    }
    .profilepicWrapper {
      width: 100%;
      padding: 40px 0;
      justify-content: center;
      display: flex;
    }
  }

  .passWrapper {
    flex-direction: column;
  }
  .submitButton {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .themeVisibilty {
    width: 65% !important;
  }
}
