.SearchableSelect {
  position: relative;
  .SelectHeader {
    border: 1px solid black;
    border-radius: 7px;
    margin-bottom: 5px;
    padding: 8px 10px;
    font-size: 12px;
    display: flex;
    justify-content: end;

    &.placeholder {
      color: #888; // Change this color to the desired placeholder text color
    }

    .iconWrapper {
      padding-left: 10px;
      border-left: 1px solid rgb(180, 180, 180);
    }
  }

  .placeholderTrue {
    justify-content: space-between;
  }

  .inputWrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    border-radius: 7px;
    margin-bottom: 5px;
    padding: 7px 10px;
    font-size: 12px;
    width: 100%;
    background-color: #f5f7f8;
    z-index: 1000;

    input {
      width: 100%;
      padding-left: 0px !important;
    }
  }

  .iconWrapper {
    padding-left: 10px;
    // padding-top: 1px;
    border-left: 1px solid rgb(180, 180, 180);
  }

  .Options {
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    margin-bottom: 5px;
    font-size: 12px;

    ul {
      padding: 0;
      margin: 0;
      padding: 10px 15px;
      border-radius: 8px;
      max-height: 320px;
      overflow: auto;
      background-color: white;
      box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);

      li {
        list-style: none;
        padding: 15px 0;
        border-bottom: 1px dashed rgb(168, 168, 168);
        cursor: pointer;
      }

      .SelectedOption {
        color: var(--accecent-color);
        font-weight: bold;
      }
    }
  }
}
