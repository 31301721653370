// antd

.ant-select-selector {
  height: 39px !important;
}

.menuBarSpinner .ant-spin {
  color: white;
}

body {
  .mobileHomeThemeSelect {
    background-color: #00565b;
    border-radius: 4px;
    color: #ffffff;
    padding: 15px 0;

    margin-bottom: 16px;
    .ant-select-arrow {
      color: #ffffff !important;
      span > svg {
        border: none !important;
      }
    }
    .ant-select-selector {
      border: none !important;
      height: auto !important;
      padding: 0 !important;
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        padding-left: 15px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        color: #ffffff !important;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        line-height: unset !important;
      }
    }
  }
}

.ant-collapse {
  background-color: white !important;
  border: none !important;
  border-radius: 15px !important;
  padding: 5px !important;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12) !important;
  margin-bottom: 20px !important;
}

.ant-collapse-header,
.ant-collapse > .ant-collapse-item {
  border: none !important;
}

.ant-collapse-header .ant-collapse-expand-icon {
  position: absolute !important;
  right: 5px !important;
}

.professional-signup-steps {
  display: none !important;
}

@media only screen and (max-width: 440px) {
  .ant-steps {
    margin-left: -10px !important;
    align-items: center !important;
  }
}
@media only screen and (max-width: 415px) {
  .ant-steps {
    margin-left: -15px !important;
  }
}

@media only screen and (max-width: 405px) {
  .ant-steps {
    margin-left: -20px !important;
  }
}

@media only screen and (max-width: 395px) {
  .ant-steps {
    margin-left: -25px !important;
  }
}

@media only screen and (max-width: 540px) {
  .ant-steps {
    margin-left: 0px;
    align-items: center !important;
  }
  .ant-steps
    .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    display: none !important;
  }

  .custom-steps .ant-steps-item-process .ant-steps-item-tail::after,
  .custom-steps .ant-steps-item-wait .ant-steps-item-tail::after,
  .custom-steps .ant-steps-item-finish .ant-steps-item-tail::after {
    width: 125% !important;
  }
}

.custom-steps .ant-steps-item-process .ant-steps-item-tail::after,
.custom-steps .ant-steps-item-wait .ant-steps-item-tail::after {
  background-color: #f5f7f8 !important;
}
.custom-steps .ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: #00565b !important;
}

.custom-steps .ant-steps-item-process .ant-steps-item-tail::after,
.custom-steps .ant-steps-item-wait .ant-steps-item-tail::after,
.custom-steps .ant-steps-item-finish .ant-steps-item-tail::after {
  height: 3px;
  width: 120%;
  margin-inline: -8px;
}

.custom-steps .ant-steps-item .ant-steps-item-icon {
  background-color: white; /* Set your desired background color here */
  border-color: #f5f7f8 !important;
}
.custom-steps .ant-steps-item-active .ant-steps-item-icon,
.custom-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(
    --bg-color
  ) !important; /* Set your desired background color here */
  border-color: var(--bg-color) !important;
}

.custom-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
  svg {
    fill: #99e67b;
    margin-bottom: 7px;
  }
}

// customize the antd dropdown colors

.ant-dropdown {
  position: fixed;
}
.ant-dropdown .ant-dropdown-menu {
  background-color: var(--accecent-color) !important;
  min-width: 70px !important;
}

.ant-dropdown .ant-dropdown-menu-item:hover {
  background-color: var(--bg-color) !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: var(--bg-color) !important;
  font-size: 12px;
  font-weight: 500;
  line-height: normal !important;
}

.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 12px;
  font-weight: 500;
  line-height: normal !important;
}

// antd dropdown
.ant-select-dropdown .ant-select-item-option {
  border-bottom: 1px dashed rgb(168, 168, 168);
  height: 50px;
  display: flex;
  align-items: center;
}

.ant-select-item-option-selected {
  .ant-select-item-option-content {
    color: var(--accecent-color);
  }
}

.ant-select {
  .ant-select-arrow {
    color: var(--bg-color);

    svg {
      height: 15px;
      width: 30px;
      padding-left: 10px;
      border-left: 1px solid rgb(180, 180, 180);
    }
  }
}

.ant-select-item,
.ant-select-item-option,
.ant-select-item-option-selected {
  background-color: transparent !important;
}

.ant-select-single {
  width: 100%;
}

.ant-select {
  .ant-select-selector {
    border: 1px solid black !important;
    box-shadow: none !important;
  }
}

.ant-select-open {
  .ant-select-selector {
    border: 1px solid var(--accecent-color) !important;
    box-shadow: none !important;
  }
}

.ant-select-selector {
  background-color: transparent !important;
}

.physicalTransformationSelect {
  .ant-select-selector {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}

.ant-select-selection-placeholder {
  padding-top: 3px !important;
  color: gray !important;
  font-size: 12px;
  // font-weight: 500;
}

.ant-select-selection-item {
  padding-top: 3px !important;
  font-size: 12px;
  color: black !important;
}

.ant-switch.ant-switch-checked {
  background-color: var(--accecent-color) !important;
}

.ant-select
  .css-dev-only-do-not-override-w8mnev
  .ant-select-single
  .ant-select-show-arrow {
  background-color: red !important;
}

// .ant-rate-star.ant-rate-star-full,
// .ant-rate-star.ant-rate-star-zero,
// .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
//   border: 1px solid red !important;
// }

.ant-rate-star.ant-rate-star-full {
  svg {
    fill: none !important;
    stroke: #00565b;
  }
}

.ant-message {
  z-index: 9999999;
}
