.footer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  color: white;
}

.main-footer {
  width: 100%;
  background-color: #01989f;
  border-radius: 10px;
  padding: 30px 24px;
  display: flex;
  justify-content: space-between;
}

.heading {
  line-height: 1.57;
  font-size: 28px;
  font-weight: 500;
}

.description {
  text-transform: uppercase;
  font-size: 14px;
}

@media (max-width: 491px) {
  .heading {
    font-size: 22px !important;
    font-weight: 400 !important;
  }

  .description {
    font-size: 12px !important;
  }
}

.icons {
  margin-top: 20px;
  display: flex;
  gap: 1.3rem;
  a {
    transition: all 0.2s;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.main-right {
  width: 50%;
  text-align: end;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  a {
    color: white;
    text-decoration: underline !important;
    text-decoration-thickness: 2px !important;
    text-underline-position: under;
    // text-underline-offset: 1px;
    text-decoration-color: #99e67b !important;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
  }
  a:hover {
    color: #99e67b;
  }
  h6 {
    cursor: pointer;
    &:hover {
      color: #99e67b;
    }
  }
}

.link {
  font-weight: normal;
  // text-decoration: underline;
}

.secondary-footer {
  width: 100%;
  background-color: #f5f7f8;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerLinks {
  margin: 20px 0px;
  display: flex;
  line-height: 22px;
  text-align: center;
  justify-content: center;

  .linksGroup {
    position: relative;
    display: flex;
    a {
      cursor: pointer;
      display: flex;
      justify-content: center;
      text-align: center;
      color: #535353;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-position: under;
      text-underline-offset: 2px;
      text-decoration-color: #535353;
      cursor: pointer;
      margin: 0;
      font-size: 12px;
      &:hover {
        text-decoration-color: #000000;
        color: #000000;
      }
    }

    .link1,
    .link2,
    .link3 {
      border-right: 1px solid #ccc;
      padding-inline: 12px;
    }

    .link4 {
      padding-inline: 12px;
    }
  }
}

@media (max-width: 512px) {
  .footerLinks {
    flex-direction: column;
    justify-content: space-around;

    .linksGroup {
      justify-content: space-around;
      padding-bottom: 10px;

      .line1 {
        position: absolute;
        height: 20px;
        width: 1px;
        top: 2px;
        left: 124.5px;
        background-color: #ccc;
      }

      .link1,
      .link2 {
        border-right: none;
      }
    }
  }
}

// @media (max-width: 491px) {
//   .link3 {
//     border: 0;
//   }
// }

// @media (max-width: 468px) {
//   .footerLinks {
//     margin-inline: 90px;
//     justify-content: center;
//     // flex-direction: column;

//     .link2,
//     .link3 {
//       border: 0;
//       padding: 0;
//     }

//     // .link3 {
//     //   border-right: 1px solid #ccc;
//     // }
//   }
// }

// @media (max-width: 440px) {
//   .footerLinks {
//     margin-inline: 70px;
//   }
// }

// @media (max-width: 384px) {
//   .link3 {
//     border: 0 !important;
//   }
//   .link1 {
//     padding-right: 12px !important;
//   }
//   .link1,
//   .link2,
//   .link3 {
//     padding-right: 0px;
//   }
// }

// @media (max-width: 343px) {
//   .link1 {
//     padding-right: 0px !important;
//   }
// }

// @media (max-width: 344px) {
//   .link1 {
//     border: 0;
//   }
// }

@media (max-width: 768px) {
  .footer {
    width: 100%;
  }

  .main-footer {
    flex-direction: column;
  }

  .main-right {
    text-align: start;
    margin-top: 30px;
    gap: 20px;
    width: 100%;
  }
}
