.CustomInput,
.CustomTextArea {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    padding-top: 4px;
    padding-bottom: 6px;

    img {
      height: 20px;
      width: 20px;
      margin-bottom: 5px;
    }
  }

  .emogie {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 14px;

    img {
      height: 15px;
      width: 15px;
    }
  }

  .subtitle {
    font-size: 12px;
    line-height: 1;
    padding: 10px 0;
    color: black;
  }

  .inputWrapper {
    position: relative;
    border-radius: 4px;
    border: solid 1px #191919;
    // margin: 6px 0;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      outline: none;
      width: 100%;
      background-color: transparent;
      font-size: 12px;
      font-style: italic;
    }

    textarea {
      outline: none;
      width: 100%;
      background-color: transparent;
      font-size: 13px;
      opacity: 1;
      color: black;
      font-style: italic;
    }

    .charCount {
      position: absolute;
      bottom: 0;
      right: 10px;
      color: #9c9c9c;
      font-size: 10px;
      font-style: italic;
    }

    input[type="file"] {
      cursor: pointer;
    }

    input[type="file"]::-webkit-file-upload-button {
      display: none;
    }

    .fontawesome {
      padding-right: 10px;
    }
  }

  .emogiePicker {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 75px;
    z-index: 1000;
  }
}

@media only screen and (max-width: 768px) {
  .emogiePicker {
    right: -25px !important;
    top: 75px;
  }
}
