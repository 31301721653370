.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
}

.white {
  animation: whiteColor 1s infinite linear alternate;
}

.accent {
  animation: accentColor 1s infinite linear alternate;
}
@keyframes whiteColor {
  0% {
    box-shadow: 20px 0 #fff, -20px 0 #fff2;
    background: #fff;
  }
  33% {
    box-shadow: 20px 0 #fff, -20px 0 #fff2;
    background: #fff2;
  }
  66% {
    box-shadow: 20px 0 #fff2, -20px 0 #fff;
    background: #fff2;
  }
  100% {
    box-shadow: 20px 0 #fff2, -20px 0 #fff;
    background: #fff;
  }
}

@keyframes accentColor {
  0% {
    box-shadow: 20px 0 #01989f, -20px 0 #018b92;
    background: #01989f;
  }
  33% {
    box-shadow: 20px 0 #01989f, -20px 0 #018b92;
    background: #018b92;
  }
  66% {
    box-shadow: 20px 0 #018b92, -20px 0 #01989f;
    background: #018b92;
  }
  100% {
    box-shadow: 20px 0 #018b92, -20px 0 #01989f;
    background: #01989f;
  }
}
