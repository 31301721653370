.notificationsPopup {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    h5 {
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
      //   color: #191919;
    }
  }

  .notificationsList {
    overflow-y: auto !important;
    height: 100%;
    max-height: 374px;
    padding-right: 10px;

    .unReadNotification {
      background-color: rgba(1, 152, 159, 0.1);
    }
    .notification {
      border-top: 2px solid #dbdbdbb9;
      padding: 10px 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: start;

      .leftSide {
        width: 20%;

        .imgWrapper {
          position: relative;
          height: 50px;
          width: 50px;

          .profilePicture {
            border-radius: 50%;
          }

          img,
          .profilePicture {
            height: 100%;
            width: 100%;
          }

          .emogieWrapper {
            position: absolute;
            bottom: -3px;
            right: 3px;
            background-color: #d5d5d5;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            width: 25px;
            .emogie {
              height: 18px;
              width: 20px;
            }
          }
        }
      }

      .content {
        padding-left: 10px;
        width: 80%;
        .notify {
          font-size: 14px;
          line-height: 1.43;
        }

        .date {
          font-size: 12px;
          font-style: italic;
          line-height: 1;
          padding-top: 5px;
        }
      }
    }
  }
}

.customBackdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}
