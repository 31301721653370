.StoryModalBody {
  // background-color: red;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    // padding-inline: 20px;

    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #191919;
      margin: 0;
    }

    button {
      .close {
        font-size: 30px !important;
      }
    }
  }

  .content {
    // padding-inline: 20px;
    p {
      // opacity: 0.72;
      font-size: 12px;
      line-height: 1.67;

      strong {
        color: black;
      }
    }

    .title {
      display: flex;
      align-items: baseline;
      padding-top: 30px;
      padding-bottom: 5px;

      h5 {
        font-size: 12px;
        font-weight: 600;
        font-style: italic;
        padding-right: 15px;
        line-height: 1;
        color: var(--accecent-color);
        text-transform: uppercase;
        white-space: nowrap;
      }

      span {
        font-weight: 300;
        text-transform: lowercase;
        font-style: normal;
      }

      .line {
        width: 100%;
        // padding-left: 20px;
        height: 1px;
        background-color: var(--accecent-color);
      }
    }

    .SearchProfessionals {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      label {
        font-size: 12px;
        font-weight: 600;
        color: #191919;
      }

      .inputWrapper {
        border-radius: 4px;
        border: solid 1px #191919;
        margin: 10px 0;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          outline: none;
          width: 90%;
          background-color: transparent;
        }

        input[type="file"] {
          cursor: pointer;
        }

        input[type="file"]::-webkit-file-upload-button {
          display: none;
        }

        .fontawesome {
          padding-right: 10px;
        }
      }
    }

    .resourcesTitle {
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 600;
      padding-top: 10px;
      padding-bottom: 9px;
      color: #191919;

      span {
        img {
          height: 20px;
          width: 20px;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }
    }

    .title {
      font-size: 12px;
      font-weight: 600;
      padding: 0px;
      padding-bottom: 5px;
      color: #191919;

      span {
        img {
          height: 20px;
          width: 20px;
          margin-left: 5px;
          margin-bottom: 5px;
        }
      }
    }

    // compbine dropdown and input fields

    .dropdownAndInputWrapper {
      display: flex;
      align-items: start;
      justify-content: space-between;
      gap: 20px;

      .selectWrapp {
        width: 30%;
      }

      .inputWrapp {
        width: 70%;
      }
    }

    .timepickerWrapp {
      display: flex;
      align-items: start;
      gap: 20px;
      justify-content: space-between;
    }

    // Resources radio buttons

    .resourceContainer {
      margin: 5px 0;
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;

      button {
        border: solid 1.5px #d5d5d5;
        padding: 5px;
        padding-inline: 10px;
        border-radius: 16px;
        font-size: 12px;
        color: #d5d5d5;
      }
      button:hover {
        background-color: var(--bg-color);
        color: #fff;
      }

      .activeResource {
        background-color: var(--bg-color);
        color: #fff;
      }
    }

    .physicalTransformation {
      background-color: rgba(0, 0, 0, 0.07);
      margin-inline: -15px;
      padding: 20px 15px;
      // margin-top: 10px;
      // margin-bottom: 30px;

      .traitement {
        padding-top: 5px;
      }
    }

    .inputsWrapper {
      display: flex;
      // gap: 20px;
      column-gap: 20px;
      justify-content: space-between;
      div {
        width: 100%;
      }
    }

    .inputGroup {
      display: flex;
      justify-content: space-between;

      .inputContainer {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        label {
          font-size: 12px;
          font-weight: 600;
          color: #191919;
        }

        input {
          border-radius: 4px;
          border: solid 1px #191919;
          margin-top: 0px;
          padding: 10px 10px;
          outline: none;
          font-size: 12px;
          font-style: italic;
          color: #484848;
        }

        /* Styles to hide the calendar icon */
        input[type="date"]::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          display: block;
          background: url(../../../assets//images/calenderIcon.svg) no-repeat;
          border-width: thin;
          padding-right: 5px;
        }

        input[type="date"]::-webkit-inner-spin-button {
          display: none;
          -webkit-appearance: none;
        }

        input[type="date"],
        input[type="time"] {
          outline: 0;
          // color: gray;
          position: relative;
          width: 100%;
          -moz-appearance: textfield;
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="date"]::-webkit-calendar-picker-indicator,
        input[type="time"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }

        .inputEyeWrapper {
          display: flex;
          position: relative;
          button,
          img,
          .calenderIcon {
            position: absolute;
            right: 6px;
            top: 4px;
          }
          .calenderIcon {
            right: 13px;
            top: 7px;
            display: none;
            cursor: pointer;
          }

          .eyeClose {
            padding-top: 2px;
            padding-right: 1px;
          }

          button {
            .slash {
              position: absolute;
              top: 6px;
              right: 16px;
              padding-top: 20px;
              width: 1px;
              height: 20px;
              transform: rotate(30deg);
              background-color: rgb(58, 57, 57);
            }
          }
        }
      }
    }

    // Radio buttons

    .radioButtonsWrapper {
      display: flex;
      .radioButtonsGrp {
        margin: 1rem 0;
      }

      input[type="radio"] {
        display: none;
      }

      label {
        cursor: pointer;
        position: relative;
        font-size: 13px;
        color: #191919;
        padding-left: 30px;
        padding-right: 10px;
      }

      label::before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: transparent;
        border: 2px solid #484848;
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: border-color 400ms ease;
      }

      label::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: var(--accecent-color);
        border: 2px solid var(--accecent-color);
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translateY(-50%) scale(0);
        transition: transform 400ms ease;
      }

      input[type="radio"]:checked + label {
        color: var(--accecent-color);
      }

      input[type="radio"]:checked + label::before {
        border-color: var(--accecent-color);
      }

      input[type="radio"]:checked + label::after {
        transform: translateY(-50%) scale(0.55);
      }
    }

    // Checkboxes

    .transformCheckbox,
    .isAnonymCheckbox,
    .checkbox {
      display: flex;
      align-items: center;
      padding: 0px 15px;
      margin-inline: -15px;
      margin-bottom: 10px;

      svg {
        height: 20px;
        width: 20px;
      }

      p {
        padding-left: 10px;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .transformCheckbox {
      background-color: rgba(0, 0, 0, 0.07);
    }

    .transformChkbox {
      background-color: rgba(0, 0, 0, 0.07);
      padding: 15px 15px;
      margin-inline: -15px;
      margin-top: 10px;
    }

    // Submit button

    .SubmitStoryButtonWrapper {
      margin-top: 20px;
      font-size: 12px !important;
      button {
        background-color: var(--customButton-bg);
        text-align: center;
        padding: 12px 15px;
        border-radius: 4px;
        margin-bottom: 10px;
        cursor: pointer;
        width: 100%;
        color: white;
        text-transform: uppercase;
      }
      // width: 40%;
    }
  }

  .submitButtonWrapper {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    margin-inline: auto;
    .submitButton {
      color: #fff;
      border-radius: 4px;
      background-color: var(--accecent-color);
      padding: 10px 20px;
      text-transform: uppercase;
    }
  }
}
.PhysicalInput,
.textArea {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  label {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
  }

  .physicalinputWrapper {
    display: none;
  }

  .inputWrapper {
    position: relative;
    border-radius: 4px;
    border: solid 1px #191919;
    margin: 6px 0;
    padding: 8px 10px;
    // display: none;
    justify-content: space-between;
    align-items: center;

    input {
      outline: none;
      width: 90%;
      background-color: transparent;
      font-size: 12px;
      font-style: italic;
    }

    textarea {
      outline: none;
      width: 100%;
      background-color: transparent;
      font-size: 13px;
      opacity: 1;
      color: black;
      // font-style: italic;
    }

    .charCount {
      position: absolute;
      bottom: 0;
      right: 10px;
      color: #9c9c9c;
      font-size: 10px;
      font-style: italic;
    }

    input[type="file"] {
      cursor: pointer;
    }

    input[type="file"]::-webkit-file-upload-button {
      display: none;
    }

    .fontawesome {
      padding-right: 10px;
    }
  }

  .CustomizeInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    border: solid 1px #191919;
    margin: 6px 0;
    padding: 8px 10px;

    p {
      color: #9c9c9c;
      font-size: 12px;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 768px) {
  .submitButtonWrapper {
    width: 100%;
    .NextButton {
      width: 100%;
      padding: 20px !important;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .inputsWrapper {
    flex-direction: column;
  }

  .SubmitStoryButtonWrapper {
    width: 100% !important;
  }

  .inputGroup {
    flex-direction: column;

    .inputContainer {
      width: 100% !important;

      input {
        width: 95%;

        // margin-top: 5px !important;
      }

      input[type="date"],
      input[type="time"] {
        outline: 0;
        position: relative;
        width: 100%;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
        height: 35px !important;
      }

      input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        // left: 0;
        position: absolute;
        right: 6px;
        top: 4px;
        width: 20px;
        z-index: 1000;
      }
      input[type="date"] {
        display: inline-flex;
      }

      .inputEyeWrapper {
        img {
          display: block !important;
        }
      }
    }
  }
}

.profilepicWrapper {
  padding: 20px 0;
  // display: flex;
  width: 20%;

  .form-left {
    width: fit-content;
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0.2;
      background-color: #191919;
      transition: 0.3s ease;
      border-radius: 5px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
    input {
      display: none;
    }
    label {
      display: block;
      cursor: pointer;
      text-align: center;
      font-weight: 500;
      color: red;
    }
    .image-upload-button {
      height: 95px;
      width: 95px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: var(--bg-color);
    }
    img {
      height: 95px;
      width: 95px;
      display: block;
      border-radius: 5px;
      object-fit: cover;
    }
  }
  .form-left:hover .overlay {
    opacity: 0.6;
  }
}
