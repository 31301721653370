.resourcesContainer {
  .backButton,
  .nextButton {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;

    p {
      text-transform: uppercase;
      opacity: 0.8;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .nextButton {
    padding-bottom: 0;
    padding-top: 20px;
    justify-content: end;

    svg {
      transform: rotate(180deg);
    }

    p {
      padding-top: 2px;
    }
  }

  .topContent {
    .heading,
    .resourceDetailHeading,
    .resourceDetailHeading2 {
      line-height: 1;
      padding-top: 15px;
      text-align: center;
    }

    .heading,
    .resourceDetailHeading {
      color: var(--accecent-color);
      font-weight: 600;
      padding-bottom: 10px;
    }

    .heading,
    .resourceDetailHeading2 {
      font-size: 18px;
    }

    .resourceDetailHeading {
      font-size: 14px;
      text-transform: uppercase;
    }

    .resourceDetailHeading2 {
      font-weight: bold;
      color: #191919;
      padding-bottom: 20px;
    }

    .subHeading {
      font-size: 18px;
      // line-height: 1.78;
      text-align: center;
      padding-bottom: 20px;
    }

    .paragraph {
      font-size: 12px;
      line-height: 1.33;
      text-align: center;
    }

    .description {
      padding-bottom: 10px;
    }

    .discriptionsContainer {
      align-items: start;
    }
  }

  .resourceBoxes {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    margin: 40px 0;

    .resourceBox {
      position: relative;
      cursor: pointer;
      border-radius: 12px;
      box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
      background-color: #fff;
      padding: 20px 50px;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      min-height: 180px;
      min-width: 250px;
      width: 100%;
      text-align: center;
      img {
        margin-bottom: 10px;
        height: 40px;
        width: 40px;
      }

      .resourceIcon {
        height: 60px;
        width: 80px;
      }

      .resourceIconPodcast {
        height: 60px;
        width: 50px;
      }

      .svgContainer {
        margin-bottom: 20px;
        svg {
          height: 60px;
          width: 60px;
        }
      }

      h4 {
        padding-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: normal;
      }

      .resourceDetailSubHeading {
        text-align: center;
        font-size: 14px;
      }

      .resourceDetailSubTitle {
        text-align: center;
        font-size: 12px;
        padding-top: 5px;
      }

      .icon {
        position: absolute;
        bottom: -40px;
        img {
          margin-bottom: 20px;
        }
      }
    }

    .selectedResourceBox {
      background-color: var(--accecent-color);
      color: #fff;

      // svg {
      //   fill: #fff !important;
      // }
    }
  }

  .resourceDetailsDropdown {
    margin-bottom: 40px;
  }

  .editButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-bottom: 10px;

    .editIcon {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .title {
    color: #191919;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    text-align: center;
  }

  .resourceListWrapper {
    .resourceDetailJoin {
      position: relative;
      display: flex;
      flex-direction: column !important;
      padding: 20px !important;

      .top {
        justify-content: start !important;
        align-items: start !important;
        .circleGreen {
          height: 10px;
          width: 3%;
          background-color: var(--accecent-color);
          border-radius: 50%;
          margin-top: 3px !important;
          margin-right: 10px !important;
        }

        .resourceDetailHeading {
          width: 97% !important;
        }
      }

      .bottom {
        .details {
          margin-left: 20px !important;
        }
      }

      .descriptionPoints,
      .description,
      .bold {
        padding-right: 0px !important;
        padding-top: 0px !important;
        padding-bottom: 20px !important;
      }

      .bold {
        font-weight: 600;
      }
    }

    .resourceList,
    .resourceDetail,
    .resourceDetailJoin {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      background-color: #fff;
      padding: 10px;
      margin-bottom: 20px;
      cursor: pointer;

      .resourcelistHeadingWrapper,
      .resource3listHeadingWrapper {
        width: 100%;
        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .resourcelistHeading,
          .resourceDetailHeading {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: normal;
          }

          .iconWrapper {
            // height: 10%;
            // width: 20%;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .descriptionWrapper {
            display: flex;
            align-items: start;

            .symbol {
              color: var(--accecent-color);
              font-size: 18px;
              padding-top: 3px;
              padding-right: 5px;
            }
          }
        }

        .coverPhotoView {
          height: auto;
          width: 140px;
          margin: 10px;
          margin-inline: auto;
          img {
            height: 100%;
            width: 100%;
          }
        }

        .descriptionPointsWrapp {
          display: flex;
          align-items: start;
          justify-content: start;
          span {
            width: 5%;
            height: 1px;
            background-color: #797979;
            margin-top: 10px;
            margin-left: 40px;
            margin-right: 10px;
          }

          .descriptionPoints {
            width: 95%;
            padding-top: 0px;
          }
        }

        .descriptionPoints,
        .description,
        .bold {
          font-size: 14px;
          // line-height: 1;
          padding-right: 10px;
          padding-top: 10px;
          color: black;
        }

        .bold {
          font-weight: 600;
        }

        .pointsWrapper {
          padding-left: 30px;

          .point {
            display: flex;
            // justify-content: center;
            align-items: start;
            .pointLine {
              height: 1px;
              width: 5%;
              margin-top: 17px;
              margin-right: 5px;
              background-color: #191919;
            }

            p {
              width: 95%;
            }
          }
        }

        .resourcelistHeading {
          color: var(--accecent-color);
        }
      }

      .resourcelistHeadingWrapper {
        // width: 50%;
        .description {
          display: block;
        }
      }

      .imageContainer {
        display: flex;
        align-items: end;
        justify-content: end;
        width: 50%;
        .imageWrapper {
          // max-height: 350px;
          // max-width: 350px;
          height: 150px;
          width: 150px;

          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .resourceDetail {
      padding: 15px;
      padding-left: 20px;
    }

    .resourcesInputs {
      border-radius: 8px;
      background-color: #fff;
      padding: 10px;
      margin-bottom: 10px;

      .label {
        font-size: 12px;
        font-weight: 600;
        padding-bottom: 8px;
        color: #191919;
      }
      .input {
        border: 1px solid #c7c7c7;
        width: 100%;
        border-radius: 6px;
        padding: 10px;
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 1;
        padding-right: 10px;
      }

      .photoInputWrapp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .PhysicalInput {
          width: 87%;
        }
        .PhysicalInput,
        .textArea {
          display: flex;
          flex-direction: column;

          label {
            font-size: 12px;
            font-weight: 600;
            color: #000000;
          }

          .label {
            width: fit-content;
            cursor: pointer;
          }

          .physicalinputWrapper {
            display: none;
          }

          .inputWrapper {
            position: relative;
            border-radius: 4px;
            border: solid 1px #191919;
            // margin: 6px 0;
            padding: 8px 10px;
            // display: none;
            justify-content: space-between;
            align-items: center;

            input {
              outline: none;
              width: 90%;
              background-color: transparent;
              font-size: 12px;
              font-style: italic;
            }

            textarea {
              outline: none;
              width: 96%;
              background-color: transparent;
              font-size: 13px;
              opacity: 1;
              color: black;
              scrollbar-width: thin;
              // font-style: italic;
            }

            textarea::-webkit-scrollbar-thumb {
              display: none;
            }

            .emogie {
              cursor: pointer;
              position: absolute;
              right: 11px;
              bottom: 15%;
              img {
                height: 15px;
                width: 15px;
              }
            }
            .emogiePicker {
              display: flex;
              justify-content: flex-end;
              position: absolute;
              right: 0;
              top: 60px;
              z-index: 1000;
            }

            .charCount {
              position: absolute;
              bottom: 0;
              right: 10px;
              color: #9c9c9c;
              font-size: 10px;
              font-style: italic;
            }

            input[type="file"] {
              cursor: pointer;
            }

            input[type="file"]::-webkit-file-upload-button {
              display: none;
            }

            .fontawesome {
              padding-right: 10px;
            }
          }

          .CustomizeInput {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px;
            border: solid 1px #191919;
            margin-bottom: 8px;
            padding: 8px 10px;
            cursor: pointer;
            overflow: hidden;
            margin-right: 10px;

            p {
              color: #797979;
              font-size: 12px;
              font-weight: 400;
              white-space: nowrap;
              overflow: auto;
            }

            p::-webkit-scrollbar {
              width: 0px;
              height: 0px;
            }
          }
        }
      }

      .photoWrapp {
        height: auto;
        width: 50px;
        border-radius: 10px;
        img {
          border-radius: 10px;
          height: 100%;
          width: 100%;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 20px;

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        color: white;
        padding: 5px;
        height: 40px;
        width: 80px;
        cursor: pointer;
      }

      .buttonCancle {
        background-color: var(--red-bg);
        &:hover {
          background-color: var(--customButton-bgHover);
        }
      }

      .buttonSave {
        background-color: var(--accecent-color);
        &:hover {
          background-color: var(--accecent-colorHover);
        }
      }
    }
  }

  .recommendResourceBtn {
    border-radius: 4px;
    border: solid 2px var(--bg-color);
    padding: 12px 10px;
    width: 40%;
    margin-inline: auto;
    cursor: pointer;
    margin-top: 50px;

    p {
      color: var(--bg-color);
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: start;
    width: 100%;

    .icon {
      cursor: pointer;

      svg {
        height: 18px;
      }
    }
  }
}
.svgLink {
  svg {
    fill: #fff;
  }
}

.sharePrivate {
  svg {
    fill: #fff;
    padding-bottom: 3px;
    height: 20px !important;
    width: 20px !important;
    g {
      fill: #fff;
      g {
        fill: #fff;
      }
    }
  }
}
.dropdownMenu {
  display: flex;
  flex-direction: row;
}

.reactionsWrapper {
  position: absolute;
  right: 30px;
  bottom: -30px;
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 24px;
  border: solid 1px rgba(25, 25, 25, 0.12);
  background-color: #f6f6f6;
  padding: 3px;
  > div:nth-last-child(2) {
    margin-left: 4px;
  }
  button, p {
    padding: 5px;
    border-radius: 16px;
    color: #d5d5d5;
  }

  button:hover {
    background-color: #d5d5d5;
    color: #fff;
  }

  .activeReaction {
    background-color: #d5d5d5;
    color: #fff;
  }

  span {
    color: black;
    font-size: 14px;
  }

  .tooltipContainer {
    position: relative;
    display: flex;
    align-items: center;
  }

  .tooltipText {
    visibility: hidden;
    width: fit-content;
    white-space: nowrap;
    background-color: #01989f;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltipText::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #01989f transparent transparent transparent;
  }

  .tooltipContainer:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
}

.topIconClass {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  padding-bottom: 30px;
  .iconWrapp {
    height: auto;
    width: 130px;

    svg {
      height: 100%;
      width: 100%;

      path {
        stroke: var(--accecent-color);
      }
      g {
        path,
        ellipse,
        polyline,
        circle,
        polygon,
        rect,
        line {
          stroke: var(--accecent-color);
        }
      }
    }
  }

  .iconWrappPng {
    height: 130px;
    // width: 130px;
    // margin-bottom: 40px;
    border-radius: 20px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .resourceBoxes {
    flex-direction: column;

    .resourceBox {
      margin-bottom: 30px;
      //   min-height: 228px;
      min-width: 304px !important;
    }
  }

  .resourcelistHeadingWrapper {
    .description {
      display: block !important;
    }
  }

  .recommendResourceBtn {
    width: 80% !important;
  }
}

// other resources stories list

.storyContent {
  p {
    color: #000000;
    opacity: 1 !important;
  }
  .heading {
    font-size: 14px;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  ul {
    padding: 0;
    // padding-top: 20px;

    .listWrapper {
      display: flex;
      align-items: start;

      img {
        padding-top: 2px;
      }

      li {
        list-style: none;
        font-size: 14px;
        font-weight: 300;
        line-height: 2.29;
      }
    }
  }
  .imgParagraphWrapper {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    padding-top: 10px;
    // padding-bottom: 20px;

    .imgWrapp {
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .paragraphWrapper {
    ul {
      padding-left: 20px;
      li {
        strong {
          opacity: 0.9;
        }
        span {
          font-weight: 300;
          opacity: 0.72;
        }
        font-size: 14px;
        line-height: 2.29;
      }
    }

    .paragraph {
      font-size: 14px;
      font-weight: 300 !important;
    }
  }

  .linkswrapper {
    // padding-bottom: 20px;
    a,
    p {
      font-size: 14px;
      font-weight: 700 !important;
      line-height: 1;
      color: var(--accecent-color);
      cursor: pointer;
    }

    p {
      text-decoration: underline;
      line-height: 20px;
    }

    a {
      border-bottom: 2px solid var(--accecent-color);
    }
  }
}

.popupListWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .courcesButton {
    padding: 5px 10px;
    color: #191919;
    border: 1px solid #191919;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
  }
}

.professionalPopupWrapper {
  text-align: center;
  padding: 20px;
  p {
    padding-bottom: 20px;
  }

  a {
    background-color: var(--red-bg);
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
  }
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 40px;

  .pageNumber {
    padding: 5px 15px;
  }

  .activePageNumber {
    border-radius: 2px;
    background-color: #f95d5f;
    padding: 5px 15px;
    color: white;
  }
}
