.body {
  .icon {
    display: flex;
    justify-content: center;
  }
  p {
    font-style: italic;
    font-size: 14px;
    line-height: 1.71;
  }
  .text {
    text-align: center;
    color: #000000;
    padding-bottom: 30px;
  }
}
