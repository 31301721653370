.profileDetailswrapper {
  position: relative;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  background-color: #fff;
  padding: 20px;
  margin-bottom: 40px;
  .bio {
    display: flex;
    justify-content: space-between;

    .profilePicWrapper {
      width: 100px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .profilepic {
        border-radius: 50%;
        width: 80px;
        height: 80px;
      }
    }

    .userInfo {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      width: 100%;
      .name {
        font-size: 24px;
        font-weight: 600;
      }

      .professionaltype {
        text-transform: capitalize;
      }

      .details {
        padding: 20px 0;
        p {
          font-size: 14px;
          font-weight: 300;
          line-height: 1.71;
          word-break: break-all;
        }
      }
    }

    .profilepicWrapper {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      justify-content: center;

      .profilepic {
        width: fit-content;
        display: inline-block;
        border-radius: 50%;
        img {
          height: 90px;
          width: 90px;
          display: block;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }

  .cunsultationButton {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: -20px;
    background-color: var(--bg-color);
    color: white;
    gap: 10px;
    margin-inline: auto;
    left: 10%;
    right: 10%;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    cursor: pointer;
  }
}

.detailContainer {
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
}
.detailTop {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .addressHeading {
    font-weight: bold;

    span {
      img {
        padding-bottom: 2px;
      }

      svg {
        margin-right: 10px;
        margin-left: 5px;
      }
    }
  }

  a {
    color: var(--accecent-color) !important;
    cursor: pointer;
    font-size: 14px !important;
  }
}

.detailBottom {
  p {
    font-size: 14px;
  }

  .expertiesWrapp {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
    p {
      padding: 8px 15px;
      border-radius: 22px;
      background-color: var(--accecent-color);
      color: white;
      width: fit-content;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .bio {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .profilePicWrapper {
      margin: 40px 0;
      padding: 20px 0;
      display: flex;
      justify-content: center;
    }

    .userInfo {
      padding-top: 20px;
      padding-left: 0px !important;
    }
  }
}
