.header {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  .heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.56;
    padding-bottom: 10px;
  }

  .details {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    span {
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 0.4px;
    }
  }

  button {
    .close {
      font-size: 30px !important;
      color: white;
    }
  }
}

.imagesWrapper {
  position: relative;
  display: flex;
  gap: 10px;
  background-color: white;
  border-radius: 10px;
  height: 350px;
  .leftSide,
  .rightSide {
    width: 50%;
    display: flex;

    justify-content: center;
    // align-items: center;
    img {
      width: 100%;
      border-radius: 10px;
      max-height: 100%;
    }
  }

  .content {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    width: 100%;
    bottom: 0;
    background-color: rgba(34, 32, 32, 0.74);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .contentLeft {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .userInfo {
        margin-right: 40px;

        h6 {
          padding-bottom: 7px;
        }
      }

      .satisfaitBtn {
        background-color: var(--accecent-color);
        &:hover {
          background-color: var(--accecent-colorHover);
        }
      }

      .pasSatisfaitBtn {
        background-color: #d5d5d5;
        color: #ff3300;
        font-weight: 700 !important;
      }

      .satisfaitBtn,
      .pasSatisfaitBtn {
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.4px;
      }
    }

    .contentRight {
      display: flex;
      align-items: center;
    }
  }
}

.mobileViewBtn {
  margin-top: 20px;
  display: none;
  justify-content: center;
  gap: 20px;

  .prevButton {
    transform: rotate(90deg);
  }

  .nextButton {
    transform: rotate(-90deg);
  }

  .prevButton,
  .nextButton {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    height: inherit;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      g {
        fill: #d5d5d5;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}

@media (max-width: 768px) {
  .satisfaitBtn,
  .pasSatisfaitBtn {
    padding: 15px 20px !important;
    font-size: 12px;
  }
  .imagesWrapper {
    height: 320px;
  }
  .contentLeft {
    width: 100%;
  }
  .contentRight {
    display: none !important;
  }

  .mobileViewBtn {
    display: flex;
  }
}
