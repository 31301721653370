.modal-dialog {
  margin-inline: auto;
  // padding-inline: 30px;
  // padding-right: 22px;
}

.createProfessionalModal {
  .modal-dialog {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .modal-dialog {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-inline: 20px;
    // padding-right: 10px;
  }
}

.SubmitStoryModal {
  .modal-dialog {
    padding-inline: 30px;
    padding-right: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .SignUpModal {
    .modal-dialog {
      padding-inline: 30px;
      padding-right: 22px;
    }
  }
}

.modal-backdrop {
  opacity: 1;
  background-color: var(--bg-color);
}

.SubmitStoryModal .modal-content {
  background-color: #f5f7f8 !important;
}

.loginModal {
  // padding-right: 9px;
}

.resourceModal {
  overflow: hidden;
}

.themesModal,
.otherResourcesModal {
  margin-left: -100px;
  overflow: hidden;
  margin-top: 11px;
  padding-right: 8px;
}

.themesModal .modal-backdrop {
  opacity: 0;
}

.otherResourcesModal .modal-backdrop {
  opacity: 0;
}

.notificationsModal {
  margin-left: -150px;
  margin-top: 123px;
}

.chatUpModal {
  margin-left: 420px;
  margin-top: 40px;

  .modal-body {
    padding: 0 !important;
  }
}

.messagingModal {
  margin-top: 1%;
}

.messagingModal .modal-content {
  border: none !important;
  background-color: transparent !important;

  .modal-body {
    padding: 0 !important;
  }
}

.professionalSignupInfoModal .modal-content {
  border: 8px solid var(--logo-color);
  border-radius: 20px;
}

@media only screen and (max-width: 768px) {
  .messagingModal {
    margin-top: 21%;
  }
}
.customBackdrop {
  background-color: rgba(0, 0, 0, 0) !important;
}

.blockUserModal {
  .modal-dialog {
    padding-inline: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .notificationsModal {
    margin-left: 0px;
    margin-top: 0px;
  }

  .chatUpModal {
    margin-left: 0%;
    margin-top: 0px;

    .modal-body {
      padding: 0 !important;
    }
  }

  .themesModal,
  .otherResourcesModal,
  .resourceModal {
    margin-inline: 0;
  }

  .themesModal,
  .otherResourcesModal {
    margin-top: 0px;
  }

  .resourceModal {
    .modal-dialog {
      padding-inline: 20px;
    }
  }

  .themesModal,
  .otherResourcesModal {
    .modal-dialog {
      padding-inline: 20px;
      padding-right: 12px;
    }
  }
}

.themesModal .modal-content,
.otherResourcesModal .modal-content,
.resourceModal .modal-content {
  background-color: var(--accecent-color);
  color: #fff;
}

.otherResourcesModal .modal-content {
  border: 8px solid var(--red-bg);
  border-radius: 20px;
  height: 80vh;
}

.beforeAfterModal .modal-dialog,
.mySubscriptionsStories .modal-dialog {
  padding: 0px !important;
}

.beforeAfterModal .modal-content,
.mySubscriptionsStories .modal-content {
  background-color: rgba(0, 87, 91, 0.9);

  height: 100vh !important;
  margin-top: -100px;
  margin-bottom: -100px;
}

.mySubscriptionsStories .modal-content {
  padding: 80px 0px;
}

.beforeAfterModal .modal-content {
  color: #fff;
  padding: 80px 400px;
}

@media only screen and (max-width: 1600px) and (min-width: 1300px) {
  .beforeAfterModal .modal-content {
    padding: 80px 400px;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1100px) {
  .beforeAfterModal .modal-content {
    padding: 80px 300px;
  }
}

@media only screen and (max-width: 1100px) and (min-width: 900px) {
  .beforeAfterModal .modal-content {
    padding: 80px 200px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 768px) {
  .beforeAfterModal .modal-content {
    padding: 80px 100px;
  }
}

@media only screen and (max-width: 768px) {
  .beforeAfterModal .modal-content,
  .mySubscriptionsStories .modal-content {
    padding: 200px 10px;
    margin-bottom: -40px;
  }
  .beforeAfterModal .modal-content {
    height: 107vh !important;
  }

  .mySubscriptionsStories .modal-content {
    height: 100vh !important;
  }
}

.slick-prev,
.slick-next {
  display: none !important;
}

.modal-header {
  .modal-title {
    color: var(--accecent-color);
  }
  button {
    background: none;
    border: none;

    span {
      color: var(--accecent-color);
      font-size: 22px;
    }
  }
}

.modal-links {
  // border-bottom: 1px solid rgb(3, 136, 224);
  color: var(--link-color);
  cursor: pointer;
}

.modal-button-cancel,
.modal-button-accept {
  border-radius: 8px;
  border: 2px solid var(--accecent-color) !important;
  padding: 5px 10px;
}

.modal-button-cancel {
  background: none;
  color: var(--accecent-color);
}

.modal-button-accept {
  background: var(--accecent-color);
  color: white;
}

.modal-ul {
  li {
    list-style: disc;
  }
}
