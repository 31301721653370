.sliderWrapper {
  position: relative;

  .arrowsWrapper {
    position: absolute;
    top: 40%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-inline: 30.8%;

    .prevButton {
      transform: rotate(90deg);
    }

    .nextButton {
      transform: rotate(-90deg);
    }

    .prevButton,
    .nextButton {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 5px;
      border-radius: 6px;
      cursor: pointer;

      svg {
        g {
          fill: #d5d5d5;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .arrowsWrapper {
      display: none;
      top: 95%;
      padding-inline: 5%;
    }
  }

  .statusCircles {
    position: absolute;
    left: 50%;
    top: 40px;
    display: flex;
    align-items: center;
    transform: translateX(-50%);
    gap: 10px;

    .circleFilled,
    .circleBordered {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      border: 1px solid var(--logo-color);
    }
    .circleFilled {
      background-color: var(--logo-color);
    }
  }

  .storiesSliderContainer {
    transition: opacity 0.3s ease-in-out;
    opacity: 0.7;
    width: 100%;
    .subscriptionStoryHeader {
      display: none;
    }
  }

  .storiesModalContainer {
    opacity: 1;
    width: 600px;
    margin-inline: auto;
    .subscriptionStoryHeader {
      display: block !important;
    }
  }

  .storiesSliderContainer,
  .storiesModalContainer {
    position: relative;
    height: 400px;
    max-height: 80vh;
    padding-inline: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    .subscriptionStoryHeader {
      position: absolute;
      top: 0px;
      right: 10px;
    }
    & > div {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .storiesSliderContainer {
      opacity: 1;

      .subscriptionStoryHeader {
        display: block !important;
      }
    }

    .storiesModalContainer {
      opacity: 1;
      width: 100%;
      margin-inline: auto;
      .subscriptionStoryHeader {
        display: block !important;
      }
    }
  }

  .activeSlide {
    opacity: 1 !important;
    .subscriptionStoryHeader {
      display: block !important;

      img {
        height: 25px;
        width: 25px;
      }
    }
  }
}

.storyOuterContainerForShareablePost {
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  background-color: #fff;
  margin-bottom: 40px;

  .repostedUserWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .picWrapper {
      height: 40px;
      width: 40px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }

    .contentWrapper {
      padding-left: 10px;

      p {
        opacity: 0.72;
        font-size: 14px;
        font-weight: normal;

        margin: 0;
      }
    }
  }

  .DisplayStoriesContainer {
    margin-bottom: 10px !important;
    border: 1px solid rgba(0, 60, 63, 0.12);
    box-shadow: rgba(50, 50, 93, 0.1) 0px 10px 20px -6px inset,
      rgba(0, 0, 0, 0.15) 0px 8px 16px -8px inset !important;
    cursor: pointer;
  }
}

.storyOuterContainer {
  position: relative;
  .DisplayStoriesContainer {
    position: relative;
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    padding: 20px;
    margin-top: 0px;
    margin-bottom: 40px;
    background-color: #fff;

    .storyHeader {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;

      .storyHeaderLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .date {
          .dateP,
          .userAnonym {
            opacity: 0.72;
          }
          .userName {
            cursor: pointer;
            color: rgba(0, 0, 0, 0.7);
            .userNameLink {
              text-decoration: underline;
              color: black;
              opacity: 0.72;
              &:hover {
                opacity: 1;
              }
            }

            .originalUserNameLink {
              text-decoration: none;
              color: black;
              font-weight: bold;
            }
          }
        }

        p {
          font-size: 14px;
          font-weight: normal;

          margin: 0;
          text-align: end;
        }
        .theme {
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          letter-spacing: 0.6px;
          color: var(--accecent-color);
          text-align: start;
          text-transform: uppercase;
        }
      }

      .storyHeaderRight {
        .title {
          font-size: 18px;
          font-weight: 600;
        }
        .heading {
          font-size: 24px;
          font-weight: 600;
        }

        .subHeading {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
        }
      }
    }

    .originalUserNameLink {
      text-decoration: none;
      color: black;
      font-weight: bold;
    }
    .storyBody {
      .storyContent {
        // .content {
        //   display: -webkit-box;
        //   -webkit-box-orient: vertical;
        //   -webkit-line-clamp: 2;
        //   overflow: hidden;
        // }
      }
      p {
        font-size: 14px;
        font-weight: normal;
        opacity: 0.82;
        margin: 0;
        padding-top: 5px;
        padding-bottom: 10px;
        overflow-wrap: break-word;
      }

      .ressource {
        margin-bottom: -4px;
      }

      .contentBtn {
        color: var(--accecent-color);
        cursor: pointer;
        padding-left: 5px;
      }

      .prefessionalResourcesWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;

        padding: 5px 0;

        div {
          cursor: pointer;
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        p {
          padding: 0;
        }

        h6 {
          margin-top: 2px;
          font-size: 12px;
          font-weight: 400;
          opacity: 0.72;
          font-weight: 600;
          text-decoration: underline;
          padding-bottom: 5px !important;
        }
      }
    }

    .smileysWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-right: 30px;
      .icons {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;

        .icon {
          cursor: pointer;
        }

        .commentsWrapper {
          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            padding-left: 5px;
          }
        }
      }
    }

    .showReactions {
      position: absolute;
      bottom: 45px;
      right: 20px;
      z-index: 100;
    }

    .reactionsContainer {
      position: absolute;
      right: 30px;
      bottom: -30px;
      display: flex;
      // justify-content: flex-end;
      width: fit-content;
      align-items: center;
      // gap: 0.5%;
      border-radius: 24px;
      border: solid 1px rgba(25, 25, 25, 0.12);
      background-color: #f6f6f6;
      padding: 3px;
      // padding-right: 15px;

      .themeFiltratin {
        display: flex;
        justify-content: center;
        align-items: center;

        .selector {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 1px;
          width: 100%;
          align-items: center;

          .selectorItem {
            // label {
            //   p {
            //     padding-left: 5px;
            //   }
            // }
            .selectorItemRadio {
              appearance: none;
              display: none;
            }
            .selectorItemLabel {
              cursor: pointer;
              text-align: center;
              display: flex;
              align-items: center;
              padding: 5px;
              // padding-inline: 10px;
              border-radius: 20px;
              transform: none;
              font-size: 14px;
            }

            // img {
            //   width: 25px;
            //   height: 25px;
            // }

            .selectorItemLabel:hover {
              background-color: #d5d5d5;
            }
            .selectorItemRadio:checked + .selectorItemLabel {
              background-color: #d5d5d5;
              // color: #fff;
            }
            @media (max-width: 480px) {
              .selector {
                width: 90%;
              }
            }
          }
        }
      }
    }
  }

  .details {
    // padding-bottom: 10px;
    // padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      // background-color: red;

      .favIcon {
        margin-bottom: 7px;
      }
      .favStarIcon {
        margin-bottom: 5px;
      }
      .icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        svg {
          height: 18px;
        }
      }

      .commentsWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
          padding: 0;
          padding-left: 5px;
        }
      }
    }
  }

  .reactionsWrapper {
    position: absolute;
    right: 30px;
    bottom: -30px;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 24px;
    border: solid 1px rgba(25, 25, 25, 0.12);
    background-color: #f6f6f6;
    padding: 3px;
    > div:nth-last-child(2) {
      margin-left: 4px;
    }
    button {
      padding: 5px;
      border-radius: 16px;
      color: #d5d5d5;
    }
    button:hover {
      background-color: #d5d5d5;
      color: #fff;
    }

    .activeReaction {
      background-color: #d5d5d5;
      color: #fff;
    }

    span {
      color: black;
      font-size: 14px;
    }

    .tooltipContainer {
      position: relative;
      display: inline-block;
    }

    .tooltipText {
      visibility: hidden;
      width: fit-content;
      white-space: nowrap;
      background-color: #01989f;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity 0.3s;
    }

    .tooltipText::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: #01989f transparent transparent transparent;
    }

    .tooltipContainer:hover .tooltipText {
      visibility: visible;
      opacity: 1;
    }
  }
}

.totalReactionsWrapper {
  display: flex;
  gap: 10px;

  img {
    width: 20px;
  }
}

.svgLink {
  svg {
    fill: #fff;
  }
}

.sharePrivate {
  svg {
    fill: #fff;
    padding-bottom: 3px;
    height: 20px !important;
    width: 20px !important;
    g {
      fill: #fff;
      g {
        fill: #fff;
      }
    }
  }
}

.dropdownMenu {
  display: flex;
  flex-direction: row;
}

.ressourceWrapper {
  text-align: center;
  .afterTitle {
    font-weight: bold;
    letter-spacing: 1px;
    padding: 5px 3px;
  }

  .para {
    font-style: italic;
    text-align: start;
    padding-top: 10px;
  }

  h4,
  h5,
  h6,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding-top: 5px;
    font-weight: normal;
  }

  a {
    text-decoration: underline;
  }
  // p {
  //   padding-top: 10px;
  // }
}

@media only screen and (max-width: 768px) {
  .details {
    flex-direction: column;
    align-items: start !important;
    justify-content: start;

    .icons {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .storyHeaderLeft {
    flex-direction: column;
    align-items: start !important;

    .date {
      display: flex;
      padding-bottom: 20px;
    }
  }

  .reactionsWrapper {
    // button:hover {
    //   background-color: transparent !important;
    // }
  }
}
