.loginModalBody {
  .loginModalBodyHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    h5 {
      font-size: 18px;
      font-weight: 600;
      color: #191919;
      margin: 0;
    }
  }

  p {
    opacity: 0.72;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
  }
  .loginForm {
    .socialLoginsWrapper {
      display: flex;
      justify-content: center;
      gap: 20px;

      img {
        cursor: pointer;
      }
    }

    .devider {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;

      p {
        height: 2px;
        width: 50%;
        opacity: 0.12;
        background-color: #333;
      }

      h4 {
        padding-inline: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .inputContainer {
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
        font-weight: 600;
        color: #191919;
      }

      .inputEyeWrapper {
        border-radius: 4px;
        border: solid 1px #191919;
        margin: 10px 0;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;

        input {
          outline: none;
          width: 90%;
        }

        button {
          position: relative;

          .slash,
          .passwordSlash {
            position: absolute;
            top: 3px;
            right: 15px;
            padding-top: 20px;
            width: 1px;
            height: 18px;
            transform: rotate(30deg);
            background-color: rgb(58, 57, 57);
          }

          .passwordSlash {
            top: 4px;
          }
        }
      }
    }

    .loginButton,
    .loaderWrapper {
      justify-content: center;
      color: #fff !important;
      width: 50%;
      display: flex;
      margin-inline: auto;
      margin-top: 20px;
    }

    .loaderWrapper {
      background-color: var(--red-bg);
      padding: 16px 15px;
      border-radius: 4px;
    }

    .signupLink,
    .forgotLink {
      color: #01989f;
      text-align: center;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      margin-inline: auto;
      cursor: pointer;
      p {
        border-bottom: 2px solid #01989f;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 768px) {
  .loginButton,
  .loaderWrapper {
    width: 100% !important;
  }
}
