// Desktop sidebar
.sidebarLeft {
  background-color: var(--sidebar-bg-color);
  border-radius: 10px;
  padding: 20px;
  color: var(--sidebar-text-color);
  width: 300px;
  height: 100%;
  position: sticky;
  top: 40px;

  .navbarMobile {
    display: flex;
  }

  a,
  a:hover {
    text-decoration: none;
    color: #fff;
  }
  .loginSignup {
    display: flex;
    align-items: center;
    padding: 32px 0;
    cursor: pointer;
    h3 {
      margin: 0;
      padding-left: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1;
      letter-spacing: normal;
    }
  }

  .menu {
    margin-top: 20px;
    ul {
      margin: 0;
      padding: 0;
      padding-left: 10px;
      li {
        display: flex;
        align-items: center;
        margin: 25px 0;

        a {
          padding-left: 10px;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #fff;
          text-decoration: none;
        }

        p {
          padding-left: 10px;
          cursor: pointer;
          margin: 0;
        }
        svg {
          height: 20px;
          width: 20px;
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .dropdownLogout {
    background-color: red !important;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 29px;
  // margin-bottom: 38px;
}

.userInfo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 30px;
  cursor: pointer;

  .leftSide {
    display: flex;
    align-items: center;
    padding-right: 10px;
    h3 {
      margin: 0;
      padding-inline: 10px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
    }
  }

  .rightSide {
    position: relative;
    padding: 4px 5px;
    border-radius: 4px;
    background-color: var(--accecent-color);

    .dot {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--red-bg);
      border: 2px solid var(--sidebar-bg-color);
    }
  }

  .detailsPopup {
    position: absolute;
    right: 10px;
    top: 58px;
    background-color: #fff;
    color: #00565b;
    border-radius: 20px;
    padding: 10px 30px;
  }
}

.message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #01989f;

  .messageLeft {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      padding-left: 10px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: normal;
    }
  }

  .messageRight {
    border-radius: 50%;
    padding: 3px 12px;
    background-color: #f95d5f;
  }
}

// Mobile menubar
.mobileMenuWrapper {
  position: relative;
}

.navbarMobile {
  height: 80px;
  background-color: #00565b;
  display: none;
  align-items: center;
  padding-inline: 20px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  .logo,
  .burger {
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .rightSide {
    display: flex;
    align-items: center;
    gap: 10px;

    .actionBtns {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .btnHome,
      .btnSubscription {
        cursor: pointer;
      }
    }
  }
}

.mobileMenu {
  display: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  // To hide desktop sidebar
  .sidebarLeft {
    display: none;
  }

  .mobileMenuWrapper {
    position: relative;
  }

  .navbarMobile {
    display: flex;
    justify-content: space-between;
  }

  .mobileMenu {
    z-index: 100;
    display: block;
    background-color: #00565b;
    height: 100%;
    width: 100%;
    position: fixed;
    // overflow: auto;
    top: 80px;
    left: 0;
    padding: 30px;
    padding-top: 0px;
    overflow-y: scroll;

    .mobileMenuContent {
      // display: flex;
      color: white;

      a,
      a:hover {
        text-decoration: none;
        color: #fff;
      }

      .loginSignup {
        display: flex;
        align-items: center;
        padding: 32px 0;
        cursor: pointer;
        h3 {
          margin: 0;
          padding-left: 10px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: italic;
          line-height: 1;
          letter-spacing: normal;
        }
      }

      .menu {
        margin-top: 20px;
        ul {
          margin: 0;
          padding: 0;
          padding-left: 10px;
          li {
            margin: 25px 0;
            display: flex;
            align-items: center;

            a {
              padding-left: 10px;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              color: #fff;
              text-decoration: none;
            }

            p {
              padding-left: 10px;
              cursor: pointer;
            }

            svg {
              height: 20px;
              width: 20px;
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      .btnWrapper {
        margin: 20px 0;
      }

      .resourcesBtnsGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        .autres {
          text-align: center;
          // width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-inline: auto;

          img {
            height: 35px;
            width: 25px;
          }
          p {
            margin-left: 5px;
            border-bottom: 2px solid white;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .topBtnContainer {
          border: 2px solid var(--red-bg);
          padding: 3px 10px;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .mobileMenu::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .mobileMenu {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
