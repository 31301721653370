@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kalam&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&display=swap");

:root {
  --sidebar-bg-color: #00565b;
  --bg-color: #00565b;
  --accecent-color: #01989f;
  --accecent-colorHover: #018b92;
  --sidebar-text-color: #fff;
  --customButton-bg: #f95d5f;
  --red-bg: #f95d5f;
  --customButton-bgHover: #e0484b;
  --link-color: rgb(3, 136, 224);
  --logo-color: #99e67b;
}

body {
  background-color: #f5f7f8 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  overflow-wrap: break-word;
}
a {
  text-decoration: none;
}

button,
input,
textarea {
  border: none;
  outline: none;
  background-color: transparent;
}

textarea {
  resize: none;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.active {
  border-left: 4px solid var(--red-bg);
  padding-left: 10px;
  a {
    font-weight: 500 !important;
  }
}

a {
  color: var(--link-color);
}

hr {
  color: gray;
  opacity: 0.5px;
  margin: 0px;
}

// for validation

.errorMessage {
  font-size: 12px;
  color: var(--red-bg);
}

.successMessage {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: var(--accecent-color);
}

.mendatory {
  color: red;
  font-weight: 300;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .main-body-container .desktop {
    justify-content: center;
    display: flex;
    padding: 0;

    margin-top: 100px;
  }

  .innerComponents {
    width: 100%;
    padding-inline: 20px;
  }
}

.Dashboard-header {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.Dashboard-policies-popup-body {
  padding-inline: 20px !important;
}
.Dashboard-policies-popup-body h6 {
  font-weight: 700;
}

.cookies-borderless-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .headings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      padding: 0px 10px;
    }
  }
}

.input-group-text {
  background-color: white !important;
}

.input-group-text,
.form-control {
  border: none !important;
  height: 100% !important;
}

.input-group {
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-radius: 8px;
  width: 100%;
  height: 44px !important;
}

// buton filled
.buttonFilled {
  background-color: var(--customButton-bg);
  text-align: center;
  padding: 5px 15px;
  height: 46px;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

.buttonFilled:hover {
  background-color: var(--customButton-bgHover);
}

.ql-size-small {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: normal;
  color: #191919;
}

.ql-size-large {
  font-size: 20px;
}

.ql-size-huge {
  font-size: 24px;
}

.MsoNormal {
  img {
    max-width: 100% !important;
  }
}
