.featherUsersContainer {
  .backButton {
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    padding-bottom: 20px;

    p {
      text-transform: uppercase;
      opacity: 0.8;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .resourceDetailHeading,
  .resourceDetailHeading2 {
    line-height: 1;
    padding-top: 15px;
    text-align: center;
  }

  .resourceDetailHeading {
    color: var(--accecent-color);
    font-weight: 600;
    padding-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
  }
  .resourceDetailHeading2 {
    font-weight: bold;
    color: #191919;
    padding-bottom: 20px;
    font-size: 14px;
  }

  .userCard {
    border-radius: 12px;
    box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
    padding: 20px;
    background-color: #fff;
    margin-bottom: 40px;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: start;

      .left {
        h6 {
          color: var(--accecent-color);
          font-weight: 600;
          font-size: 18px;
          max-width: 200px;

          span {
            padding-left: 5px;
            img {
              margin-bottom: 3px;
              height: 25px;
              width: auto;
            }
          }
        }
      }

      .right {
        button {
          background-color: var(--red-bg);
          color: white;
          border-radius: 8px;
          padding-inline: 10px;
          font-size: 12px;
        }
      }
    }

    .bottom {
      .totalFeathers {
        color: var(--accecent-color);
        font-style: italic;
        font-size: 14px;
        font-weight: 600;
      }

      .totalSubs {
        font-size: 12px;
        font-weight: 600;
      }
      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .profilepicWrapper {
          width: 40%;
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 30%;
          justify-content: center;

          .profilepic {
            width: fit-content;
            display: inline-block;
            border-radius: 50%;
            padding: 8px;
            background: linear-gradient(135deg, #fff253, #99e67b 50%, #01989f);

            img {
              height: 80px;
              width: 80px;
              display: block;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }

        .themesWrapper {
          width: 60%;
          .theme {
            background-color: var(--accecent-color);
            color: white;
            padding: 2px 5px;
            border-radius: 7px;
            margin-bottom: 5px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
