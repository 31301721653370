.buttonFilled {
  background-color: var(--customButton-bg);
  text-align: center;
  padding: 15px 15px;
  border-radius: 4px;
  // margin-bottom: 10px;
  cursor: pointer;
  width: 100%;

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  svg {
    fill: white;
    height: 25px;
  }
}

.buttonFilled:hover {
  background-color: var(--customButton-bgHover);
}
