.card {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 8px 8px 32px 0 rgba(0, 60, 63, 0.12);
  padding: 20px;
  background-color: #fff;

  h2,
  p {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1s shine linear infinite;
  }

  .cardContent {
    padding: 20px;
  }

  h2 {
    height: 30px;
    margin-bottom: 10px;
  }

  p {
    height: 70px;
    width: 80%;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
