.signUpForm {
  margin-top: 15px;
  a {
    text-decoration: underline;
  }
  .inputGroup {
    display: flex;
    justify-content: space-between;

    .inputContainer {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      width: 45%;
      label {
        font-size: 12px;
        font-weight: 600;
        color: #191919;
      }

      input {
        border-radius: 4px;
        border: solid 1px #191919;
        margin-top: 0px;
        padding: 5px 10px;
        outline: none;
      }

      /* Styles to hide the calendar icon */
      input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background: url(../../images//calenderIcon.svg) no-repeat;
        border-width: thin;
        padding-right: 5px;
      }

      input[type="date"]::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
      }

      input[type="date"],
      input[type="time"] {
        outline: 0;
        // color: gray;
        position: relative;
        width: 100%;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="time"]::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }

      .inputEyeWrapper {
        display: flex;
        position: relative;
        button,
        img,
        .calenderIcon {
          position: absolute;
          right: 6px;
          top: 4px;
        }
        .calenderIcon {
          right: 13px;
          top: 7px;
          display: none;
          cursor: pointer;
        }

        .eyeClose {
          padding-top: 2px;
          padding-right: 1px;
        }

        button {
          .slash {
            position: absolute;
            top: 6px;
            right: 16px;
            padding-top: 20px;
            width: 1px;
            height: 20px;
            transform: rotate(30deg);
            background-color: rgb(58, 57, 57);
          }
        }

        // img {
        //   display: none;
        // }
      }
    }
  }
  .NextButtonWrapper {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    margin-inline: auto;
    .NextButton {
      color: #fff;
      border-radius: 4px;
      background-color: var(--accecent-color);
      padding: 10px 20px;
      text-transform: uppercase;
    }
  }

  .signupButton {
    color: var(--accecent-color);
    text-align: center;
    margin-top: 20px;
    width: 70%;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    p {
      border-bottom: 2px solid var(--accecent-color);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .conditionsText {
    margin-top: 10px;
  }
}

.errorMsg {
  color: red;
  font-size: 12px !important;
}

@media (max-width: 768px) {
  .NextButtonWrapper {
    width: 100%;
    .NextButton {
      width: 100%;
      // padding: 20px !important;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .inputGroup {
    flex-direction: column;

    .inputContainer {
      width: 100% !important;

      input {
        width: 100%;

        // margin-top: 5px !important;
      }

      input[type="date"],
      input[type="time"] {
        outline: 0;
        position: relative;
        width: 100%;
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
        height: 35px !important;
      }

      input[type="date"]::-webkit-calendar-picker-indicator,
      input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        // left: 0;
        position: absolute;
        right: 6px;
        top: 4px;
        width: 20px;
        z-index: 1000;
      }
      input[type="date"] {
        display: inline-flex;
      }

      .inputEyeWrapper {
        img {
          display: block !important;
        }
      }
    }
  }
}
